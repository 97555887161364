import * as React from 'react';
import * as ReactDom from 'react-dom';
import * as immer from 'immer';
import { Subscribe } from '@react-rxjs/core';


import { MachineUi } from './machine';
import { SourceRenderer } from './ed';


immer.enableMapSet();

ReactDom.render(
    <React.StrictMode>
        <Subscribe fallback={ <p>Source Loading&hellip;</p> }>
            <SourceRenderer/>
        </Subscribe>
    </React.StrictMode>
    , document.getElementById('editor')
);

ReactDom.render(
    <React.StrictMode>
        <Subscribe fallback={ <p>Renderer Loading&hellip;</p> }>
            <MachineUi/>
        </Subscribe>
    </React.StrictMode>
    , document.getElementById('renderer')
);
