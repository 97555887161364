import * as Rx from 'rxjs';
import { startProg } from '../application/startprog';
import { Loc, ProcessedToken } from '../application';

/*
 * startup inputs: src lines
 *
 * mutation Inputs: KeyboardEvents
 *
 * desired outputs: cursor changes + source changes. Maybe two observables, then?
 *   - Problem: they both need state from the other:
 *     - cursorPosition needs to know the width of the current line, and
 *     - the actual editor needs to know the cursor position.
 */

// export const cursorPosition$ = new Rx.BehaviorSubject<Loc>({ line: 1, column: 0 });
// export const sourceLines$ = new Rx.BehaviorSubject<string[]>([]);

// export const sourceLines$: Rx.Observable<> = documentKeys$.pipe(
//     Rx.scan((lines, evt) => handleKeyEvent(lines, evt), src)
// );

const trace = false;

type EditObservables = {
    sourceLines$: Rx.Observable<string[]>
    cursorPosition$: Rx.Observable<Loc>
}

export type Edits =
    { type: 'noop' }
    | { type: 'hard-reset' }
    | { type: 'set-cursor', loc: Loc }
    | { type: 'replace-text', loc: Loc, eraseLength: number, newText: string };

const documentKeys$: Rx.Observable<KeyboardEvent> = (function() {
    const elt = document.getElementById('editor');
    if(!elt) { throw 'could not find #editor'; }

    trace && console.log('GOT', elt);

    return Rx.fromEvent<KeyboardEvent>(elt, 'keydown');
})();

// from https://stackoverflow.com/a/32958072
var printable = /^[\u0020-\u007e\u00a0-\u00ff]$/;

export function edit(edits$: Rx.Observable<Edits>): EditObservables {
    documentKeys$.subscribe((evt: KeyboardEvent) => handleKeyEvent(evt));

    let cursorPosition: Loc = { line: 1, column: 0 };

    // let lines = startProg.split('\n');
    let lines = (localStorage.getItem('src-doc') || startProg).split('\n');

    const sourceLines$ = new Rx.BehaviorSubject<string[]>(lines);
    const cursorPosition$ = new Rx.BehaviorSubject<Loc>(cursorPosition);

    function insertAt(text: string, loc: Loc, opts?: { updateCursor?: boolean }) {
        const newLines = text.split('\n');

        trace && console.log('newLines!', newLines);

        const line = lines[loc.line - 1];
        const a = line.slice(0, loc.column);
        const b = line.slice(loc.column, line.length);

        newLines[0] = a + newLines[0]
        newLines[newLines.length - 1] = newLines[newLines.length - 1] + b;

        lines.splice(loc.line - 1, 1, ...newLines);
        sourceLines$.next(lines);

        if(opts?.updateCursor) {
            if(newLines.length > 1) {
                cursorPosition = { line: loc.line + newLines.length - 1, column: 0 };
            } else {
                cursorPosition = { line: loc.line, column: loc.column + 1 };
            }
            cursorPosition$.next(cursorPosition);
        }
    }

    function insert(text: string, opts?: { updateCursor?: boolean, replace?: boolean }) {
        insertAt(text, cursorPosition, opts);
    }

    function deleteAt(loc: Loc, count: number, opts?: { updateCursor: boolean }) {
        trace && console.log('DELETING', loc, count);

        const line = lines[loc.line - 1];
        const prevLine = loc.line > 1 ? lines[loc.line - 2] : null;

        var nextCursorPosition = cursorPosition;

        if(loc.column < 0 && prevLine) {
            trace && console.log('  PREVLINE');
            // Deleting the first character (i.e. joining lines).
            const nextLine = prevLine + line;
            lines.splice(loc.line - 2, 2, nextLine);

            nextCursorPosition = { line: nextCursorPosition.line - 1, column: prevLine.length };
        } else {
            const a = line.slice(0, loc.column);
            const b = line.slice(loc.column+count, line.length);

            trace && console.log('  SLICE a', a, 'b', b);

            lines.splice(loc.line - 1, 1, a + b);
            nextCursorPosition = { line: nextCursorPosition.line, column: nextCursorPosition.column - 1 };
        }

        sourceLines$.next(lines);

        if(opts?.updateCursor) {
            cursorPosition = nextCursorPosition;
            cursorPosition$.next(cursorPosition);
        }
    }

    function deleteAtCursor(opts?: { updateCursor: boolean }) {
        const cur = { ...cursorPosition, column: cursorPosition.column - 1 };
        deleteAt(cur, 1, opts);
    }


    edits$.subscribe(edit => {
        switch(edit.type) {
            case 'noop':
                break;

            case 'hard-reset': {
                const lines = startProg.split('\n');
                sourceLines$.next(lines);
                break;
            }

            case 'replace-text': {
                const { column } = edit.loc;
                const line = lines[edit.loc.line-1]
                const str = line.slice(column, column + edit.eraseLength);
                trace && console.log('str', str, 'vs', edit.newText);

                if(edit.newText !== str) {
                    deleteAt(edit.loc, edit.eraseLength);
                    insertAt(edit.newText, edit.loc);
                }
                break;
            }

            case 'set-cursor': {
                cursorPosition = edit.loc;
                cursorPosition$.next(cursorPosition);
                break;
            }

        }
    })

    function handleKeyEvent(evt: KeyboardEvent): void {
        evt.preventDefault();

        const unmodified = !(evt.altKey || evt.ctrlKey || evt.metaKey);
        trace && console.log('key: ', evt.key);

        const currentLine = lines[cursorPosition.line - 1];



        function arrowRight() {
            const nextColumn = cursorPosition.column <= currentLine.length - 1 ? cursorPosition.column + 1 : currentLine.length - 1;
            cursorPosition = { ...cursorPosition, column: nextColumn };
            cursorPosition$.next(cursorPosition);
            trace && console.log('cursorPos', cursorPosition);
        }

        function arrowLeft() {
            const nextColumn = cursorPosition.column > 0 ? cursorPosition.column - 1 : 0;
            cursorPosition = { ...cursorPosition, column: nextColumn };
            cursorPosition$.next(cursorPosition);
            trace && console.log('cursorPos', cursorPosition);
        }


        switch(evt.key) {
            case 'ArrowUp': {
                const nextLine = cursorPosition.line > 1 ? cursorPosition.line - 1 : 1;
                cursorPosition = { ...cursorPosition, line: nextLine };
                // TODO Adjust column to not leave maximum on new line
                //
                // BUT: don't store that in cursorPosition, just emit it. That
                // way when continuing up and down, it will snap back to the
                // correct column. Moving left or right will do the right thing,
                // I think.
                cursorPosition$.next(cursorPosition);

                trace && console.log('cursorPos', cursorPosition);
                break;
            }

            case 'ArrowDown': {
                const nextLine = cursorPosition.line < lines.length ? cursorPosition.line + 1 : lines.length;
                cursorPosition = { ...cursorPosition, line: nextLine };
                cursorPosition$.next(cursorPosition);
                trace && console.log('cursorPos', cursorPosition);
                break;
            }

            case 'ArrowLeft': {
                arrowLeft();
                break;
            }

            case 'ArrowRight': {
                arrowRight();
                break;
            }

            case 'Backspace': {
                deleteAtCursor({ updateCursor: true });
                break;
            }

            case 'Enter': {
                insert('\n', { updateCursor: true });
                break;
            }

            case 'Alt':
            case 'Shift':
            case 'OS':
            case 'Control':
                trace && console.log(`ignoring ${evt.key} key`);
                break;

            default:
                if(printable.test(evt.key) && unmodified) {
                    insert(evt.key, { updateCursor: true });
                } else {
                    trace && console.log('ED can\'t process:', evt.key);
                }
        }
    }

    return { sourceLines$, cursorPosition$ };
}
