import {Renderers, Renderer, ObjOverrides} from '/src/application';

type CanvasRenderer<T> = Renderer<CanvasRenderingContext2D, T>;

const renderNumber: CanvasRenderer<number> = {
    render: (_, ctx, n) => ctx.fillText(n.toString(), 0, 0)
}

const renderString: CanvasRenderer<string> = {
    render: (_, ctx, s) => ctx.fillText(s, 0, 0)
}

const renderArray: CanvasRenderer<any[]> = {
    render: (render, ctx, xs) => xs.forEach(x => render(x))
}

export class CanvasRenderers implements Renderers {
    // type -> name (or 'default') -> Renderer<>
    readonly reg: { [type: string]: { [name: string]: CanvasRenderer<any> } }
    private ctx: CanvasRenderingContext2D | undefined;

    constructor() {
        this.reg = {
            'number': { 'default': renderNumber }
            , 'string': { 'default': renderString }
            , 'array': {
                  'default': renderArray
                // , 'rainbow': { render: (obj) => this.renderArray(obj, true) }
            }
        };
    }

    setCtx(ctx: CanvasRenderingContext2D): void {
        this.ctx = ctx;
    }

    putRenderer(key: string, name: string, r: CanvasRenderer<any>): void {
        if(!this.reg[key]) { this.reg[key] = { }; }
        this.reg[key][name] = r;
    }

    render(overrides: ObjOverrides, obj: any): void {
        if(!this.ctx) return;

        if(typeof overrides.fill !== 'undefined') {
            this.ctx.fillStyle = overrides.fill;
        }

        if(typeof overrides.stroke !== "undefined") {
            this.ctx.strokeStyle = overrides.stroke;
        }

        if(typeof overrides.opacity !== "undefined") {
            this.ctx.globalAlpha = overrides.opacity;
        }

        if(typeof overrides.hackMouseActions !== "undefined") {
            // TODO OMG
            (this.ctx as any).mouseAction = overrides.hackMouseActions;
        }

        if(typeof obj == 'undefined') {
            return;
        }

        if(typeof obj == 'object' && 'type' in obj && obj.type in this.reg) {
            this.reg[obj.type]['default'].render((o) => this.render(overrides, o), this.ctx, obj);
            return;
        }

        if(typeof obj in this.reg) {
            this.reg[typeof obj]['default'].render((o) => this.render(overrides, o), this.ctx, obj);
            return;
        }

        if(Array.isArray(obj)) {
            this.reg['array']['default'].render((o) => this.render(overrides, o), this.ctx, obj);
            return;
        }

        return this.reg['string']['default'].render((o) => this.render(overrides, o), this.ctx, obj.toString());
    }
}

export const renderers = new CanvasRenderers();
