import * as Rx from 'rxjs';
import Color from 'color';

import { MiniMap } from "./data/MiniMap";

import { ProcessedToken, SupportedNode } from './data';

type TrackedObjectDisplayState = 'hover' | 'selected' | 'normal'

export function isColor(o: any): o is Color {
    return o && typeof o === 'object'
           && 'model' in o
           && 'color' in o
           && 'valpha' in o;
}

export type TrackedObject = {
    tok: ProcessedToken
    parsePath: SupportedNode[]
    displayState: TrackedObjectDisplayState
    userColor: Color
}

function randColor(): Color {
    // const hue = Math.floor(Math.random() * 360);
    // return Color.hsv(hue, 74, 82);

    return Color("#b6b6b6");
}

export type TrackedObjects = MiniMap<string, TrackedObject>;

export function mkTrackedObject(tok: ProcessedToken, parsePath: SupportedNode[]): TrackedObject {
    return { tok, parsePath, displayState: 'normal', userColor: randColor() };
}

export const emptyTrackedObjects: TrackedObjects = MiniMap();

function toggle<K, V>(t: MiniMap<K, V>, k: K, v?: V): MiniMap<K, V> {
    if(t.has(k)) {
        return t.delete(k);
    }

    if(v) {
        return t.set(k, v);
    }

    return t;
}

let trackedObjects: TrackedObjects = emptyTrackedObjects;

// I really don't like exporting this...
export const trackedObjects$ = new Rx.BehaviorSubject<TrackedObjects>(trackedObjects);

export function swapTrackedObjects(k1: ProcessedToken, k2: ProcessedToken): void {
    const nxt = trackedObjects.swapEntries(k1.value, k2.value);
    trackedObjects = nxt;
    trackedObjects$.next(nxt);
}

export function moveTrackedObjectUp(k: ProcessedToken): void {
    const nxt = trackedObjects.moveUp(k.value);
    trackedObjects = nxt;
    trackedObjects$.next(nxt);
}

export function moveTrackedObjectDown(k: ProcessedToken): void {
    const nxt = trackedObjects.moveDown(k.value);
    trackedObjects = nxt;
    trackedObjects$.next(nxt);
}

export function toggleTrackedObject(k: ProcessedToken, v?: SupportedNode[]): void {
    const vv = v && mkTrackedObject(k, v);
    trackedObjects = toggle(trackedObjects, k.value, vv);
    trackedObjects$.next(trackedObjects);
}

/**
 * Sets the display color for a given {@link ProcessedToken}
 * @param k - the token
 * @param userColor - the color
 */
export function setTrackedObjectState(k: ProcessedToken, userColor: Color): void;

/**
 * Sets the display state (e.g. user interaction state) for a given {@link ProcessedToken}
 * @param k
 * @param displayState
 */
export function setTrackedObjectState(k: ProcessedToken, displayState: TrackedObjectDisplayState): void;

export function setTrackedObjectState(k: ProcessedToken, update: Color | TrackedObjectDisplayState): void {
    if(trackedObjects.has(k.value)) {
        const nxt = trackedObjects.update(k.value, v => {
            if(!v) throw `setTrackedObjectState: Could not find ${ k } in trackedObjects!`;
            if(isColor(update)) {
                return { ...v, userColor: update };
            }

            return { ...v, displayState: update };
        });
        trackedObjects = nxt;
        trackedObjects$.next(nxt);
    }
}

export function getTrackedObjectState(objs: TrackedObjects, k: ProcessedToken): TrackedObjectDisplayState {
    const v = objs.get(k.value);
    return v && v.displayState || 'normal';
}
