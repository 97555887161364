import * as React from 'react';
import { Program } from 'esprima';

import * as app from '../application';
import { isProcessedToken, Loc, ProcessedToken, Span, tokenTextSubstr } from '../application';
import * as Root from '../composition-root';
import classnames from 'classnames';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndo } from "@fortawesome/free-solid-svg-icons";

import { findPath } from '../machine';

const trace = false;

type ProcessedLine = ProcessedToken[];
type Identifiers = { [n: string]: ProcessedToken[] };

// const edits$ = new Rx.BehaviorSubject<Ed.Edits>({ type: 'noop' });
// const { sourceLines$, cursorPosition$ } = Ed.edit(edits$);

// const src$ = sourceLines$.pipe(Rx.map(l => l.join('\n')));
// // src$.subscribe(o => console.log('src$', o));

// const prog$ = src$.pipe(
//     Rx.map(parseIt), Rx.shareReplay(1)
// );

// src$.subscribe(
//     (src) => Root.setProg(parseIt(src))
// );

// const toks$ = prog$.pipe(Rx.map(p => (p && p.tokens || []) as ProcessedToken[]));


// const [useSrc,] = bind(src$);
// const [useToks,] = bind(toks$);


function tokCursor(cur: Loc, tok: ProcessedToken): React.ReactElement {
    const s = tok.value.length == 0 ? ' ' : tok.value;

    if(tok.loc.start.line <= cur.line && cur.line <= tok.loc.end.line) {
        if(tok.loc.start.column < cur.column && cur.column <= tok.loc.end.column) {
            // console.log('got tok line:', tok.type);
            // It's somewhere on this line:
            const splitPoint = cur.column - tok.loc.start.column;
            const a = s.slice(0, splitPoint);
            const b = s[splitPoint];
            const c = s.slice(splitPoint + 1, s.length);

            // console.log('  emit:', a, b, c);

            return <span>{ a }<span className="cursor">{ b }</span>{ c }</span>;
        } else if(cur.column === 0 && tok.loc.start.column === 0) {
            return <span className="cursor">{ s }</span>;
        }
    }

    return <span>{ s }</span>;
}

export const SourceRenderer: React.FC<{}> = () => {
    const tracked = Root.useTrackedObjects();
    const src = Root.useSrc();
    const toks = Root.useToks();
    const prog = Root.useProg();
    const cur = Root.useEdCursorPosition();

    const srcLines = src.split('\n');
    // srcLines.forEach(l => console.log(l));

    const [ dragToken, setDragToken ] = React.useState<{ tok: ProcessedToken, x: number, y: number, prevEditLength?: number } | undefined>();

    const [ lines ] = processSrc(src, toks);

    // console.log(lines);

    function tokMouseDown(prog: Program | undefined, tok: ProcessedToken, evt: React.MouseEvent): void {

        switch(tok.type) {
            case 'Identifier':
                prog && app.toggleTrackedObject(tok, findPath(prog, tok));
                break;

            case 'Numeric':
                if(evt.altKey) {
                    evt.preventDefault();
                    setDragToken({ tok, x: evt.clientX, y: evt.clientY });
                }
                break;
        }
    }

    function srcMouseUp(prog: Program | undefined, evt: React.MouseEvent): void {
        evt.preventDefault();
        setDragToken(undefined);
    }

    function srcMouseMove(prog: Program | undefined, evt: React.MouseEvent): void {
        if(dragToken) {
            evt.preventDefault();
            const delta = Math.round(( evt.clientX - dragToken.x ) / 10);
            const nextValue = parseInt(dragToken.tok.value) + delta;

            const eraseLength = dragToken.prevEditLength !== undefined
                                ? dragToken.prevEditLength
                                : dragToken.tok.value.length;

            trace && console.log('srcmousemove', nextValue)

            // Ok, so we can't use the start token, because that never changes length.
            // We need to know the length of the previous edit from this token.
            Root.sendEdit({
                type: 'replace-text',
                loc: dragToken.tok.loc.start,
                eraseLength,
                newText: nextValue.toString()
            });
            setDragToken({ ...dragToken, prevEditLength: nextValue.toString().length })
        }
    }

    function lineMouseDown(lineNo: number, evt: React.MouseEvent): void {
        evt.preventDefault();
        evt.stopPropagation();
        const line = srcLines[lineNo];
        const bbox: DOMRect = ( evt.target as HTMLElement ).getBoundingClientRect();
        trace && console.log('clicked line:', lineNo, 'of length:', lines[lineNo].length, 'at: ', evt.clientX);
    }

    function lineEndMouseDown(lineNo: number): void {
        const line = srcLines[lineNo];
        Root.sendEdit({ 'type': 'set-cursor', loc: { line: lineNo + 1, column: line.length } });
    }

    const lineElements = lines.map((l, i) => {
        const lineTokens = l.map(tok => (
            <span
                key={ tok.loc.start.line + ':' + tok.loc.start.column }
                className={ classnames(tok.type.toLowerCase(), app.getTrackedObjectState(tracked, tok)) }
                onMouseOver={ () => app.setTrackedObjectState(tok, 'hover') }
                onMouseOut={ () => app.setTrackedObjectState(tok, 'normal') }
                onMouseDown={ (evt) => tokMouseDown(prog, tok, evt) }
            >
                { tokCursor(cur, tok) }
            </span> ));
        return (
            <div className={ "line" }
                 onClick={ evt => lineEndMouseDown(i) }
            >
                <span
                    onClick={ evt => lineMouseDown(i, evt) } key={ i + 1 }
                >
                    { lineTokens }
                </span>
            </div> );
    });

    return (
        <div className={"editor"}>
            <div
                className={ "reset" }
                onClick={() => Root.sendEdit({ type: 'hard-reset' }) }
            >
                <FontAwesomeIcon icon={ faUndo }/>
            </div>
            <div className="src"
                 onMouseMove={ (evt) => srcMouseMove(prog, evt) }
                 onMouseUp={ (evt) => srcMouseUp(prog, evt) }
            >
                { lineElements }
            </div>
        </div>
    );
};

function processSrc(src: string, toks: ProcessedToken[]): [ ProcessedLine[], Identifiers ] {
    const lines: ( ProcessedToken & { type: 'text' } )[] = src.split('\n')
        .map((line, i) => {
            const loc: Span<Loc> = { start: { line: i + 1, column: 0 }, end: { line: i + 1, column: line.length } };
            return { type: 'text', loc, value: line };
        });

    if(!toks) {
        console.warn('Toks is null or undefined!');
        return [ lines.map(l => [ l ]), {} ];
    }

    // Group into lines
    const ltoks: { [n: number]: ProcessedToken[] } = {};
    toks.forEach(t => {
        const line = t.loc.start.line - 1;
        if(!ltoks[line]) ltoks[line] = [];
        ltoks[line].push(t);
    });


    // Build lines w/ tokens
    const out: ProcessedLine[] = [];
    const idOut: Identifiers = {};

    for(var i = 0; i < lines.length; i++) {
        if(typeof ltoks[i] == "undefined") {
            out.push([ lines[i] ]);
            continue;
        }

        const tokens: ProcessedToken[] = [];
        let lineStr = lines[i];
        let lastIdx = 0;

        for(const token of ltoks[i]) {
            if(!isProcessedToken(token)) continue;
            const r = { start: token.loc.start.column, end: token.loc.end.column };

            if(r.start > lastIdx) {
                tokens.push(tokenTextSubstr(lineStr, { start: lastIdx, end: r.start }));
            }

            tokens.push(token);

            if(typeof idOut[token.value] == 'undefined') idOut[token.value] = []
            idOut[token.value].push(token);

            lastIdx = r.end;
        }

        if(lastIdx < lineStr.value.length) {
            tokens.push(tokenTextSubstr(lineStr, lastIdx));
        }

        out.push(tokens);
    }


    return [ out, idOut ];
}
