import * as React from 'react';
import classnames from "classnames";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as I from "immutable";
import * as Rx from "rxjs";
import { bind } from "@react-rxjs/core";


let systemErrors = I.List<SysMessage>();
const systemErrors$ = new Rx.BehaviorSubject<I.List<SysMessage>>(systemErrors);
export const [ useSystemErrors ] = bind(systemErrors$);

export function addSysMessage(s: SysMessage) {
    systemErrors = systemErrors.push(s);
    systemErrors$.next(systemErrors);
}

export function clearSystemErrors() {
    systemErrors = I.List();
    systemErrors$.next(systemErrors);
}

export type SysMessage =
    { type: 'error', message: string, params?: any[] }
    | { type: 'info', message: string, params?: any[] }

export const SystemLog: React.FC<{}> = () => {
    const errors = useSystemErrors();
    const [ show, setShow ] = React.useState<boolean>(false);

    React.useEffect(() => {
        setShow(s => s || !errors.isEmpty());
    }, [ errors ]);

    function clearAfterAnimation() {
        setShow(false);
        window.setTimeout(() => {
            clearSystemErrors();
        }, 500);
    }

    const messageDom = errors.map(({ type, message, params }, idx) => {
        const str = (m: any) => typeof m === 'string' ? m : <span className={'obj'}>{JSON.stringify(m)}</span> ;
        const prms = params?.map((p, idx) => <span key={ idx }>{ str(p) }</span>);
        return (
            <div key={ idx } className={ classnames('message', type) }>
                { str(message) }{ prms }
            </div>
        );
    });

    return (
        <div className={ classnames('system-log', { 'has-errors': show }) }>
            <div className={ "clear-errors" }
                 onClick={ () => clearAfterAnimation() }><FontAwesomeIcon icon={ faTimesCircle }/></div>
            <div className={ "messages" }>
                { messageDom }
            </div>
        </div>
    );
};
