import {
      FunctionDeclaration
    , Statement
    , VariableDeclaration
    , ModuleDeclaration
    , BlockStatement
    , Expression
    , ArrayExpression
    , FunctionExpression
    , Directive
    , SpreadElement
    , Super
} from 'estree';

export type SupportedNode = FunctionDeclaration | Statement | VariableDeclaration | ModuleDeclaration | BlockStatement | Expression;
export type EvaluableExpr = FunctionExpression | Directive | Statement | ModuleDeclaration | Expression | SpreadElement | Super | ArrayExpression;

export type Loc = { line: number, column: number };
export type Span<T> = { start: T, end: T }

export function isSpan<T>(o: any): o is Span<T> {
    return typeof o === 'object' && 'start' in o && 'end' in o;
}

export function hasLoc<T>(t: T): t is T & { loc: Span<Loc> } {
    return typeof(t) === 'object' && 'loc' in t;
}

export function hasRange<T>(t: T): t is T & { range: [number, number] } {
    return typeof(t) === 'object' && 'range' in t;
}

export type ProcessedToken =
      { type: 'Keyword'   , loc: Span<Loc>, value: string }
    | { type: 'Identifier', loc: Span<Loc>, value: string }
    | { type: 'Numeric'   , loc: Span<Loc>, value: string }
    | { type: 'text'      , loc: Span<Loc>, value: string }
    | { type: 'blank'     , loc: Span<Loc>, value: '' };

export function tokenTextSubstr(t: ProcessedToken & { type: 'text' }, range: Span<number>): ProcessedToken & { type: 'text' };
export function tokenTextSubstr(t: ProcessedToken & { type: 'text' }, range: number): ProcessedToken & { type: 'text' };
export function tokenTextSubstr(t: ProcessedToken & { type: 'text' }, range: Span<number> | number): ProcessedToken & { type: 'text' } {
    const rstart = isSpan(range) ? range.start : range;
    const rend   = isSpan(range) ? range.end   : t.value.length;

    const line  = t.loc.start.line;
    const start = { line, column: t.loc.start.column + rstart };
    const end   = { line, column: t.loc.start.column + rend };
    const loc   = { start, end };

    return { type: 'text', loc, value: t.value.slice(rstart, rend) };
}

export function isProcessedToken(t: any): t is ProcessedToken {
    return typeof t == 'object' &&
            'type' in t &&
             ['Keyword', 'Identifier', 'Numeric'].includes(t.type);
}
