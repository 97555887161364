import * as React from 'react';
import classnames from 'classnames';
import { Set } from 'immutable';

import * as Root from '/src/composition-root';
import { getBinding, MachineState, TrackedObjects } from '/src/application';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFastForward } from "@fortawesome/free-solid-svg-icons";
import { hasAny, top } from "/src/machine/ui/utils";


const trace = false;

export const ExecTimeline: React.FC<{}> = ({}) => {
    const states = Root.useEvaluatedMachineStates();
    const tracked = Root.useTrackedObjects();
    const [ alwaysEnd, setAlwaysEnd ] = React.useState<boolean>(false);

    const hoveredItem = tracked.valueSeq().find(v => v.displayState === 'hover');

    trace && console.log('timeline states:', states);

    const enabled = calculateEnabledIndices(states, tracked);

    // This convoluted oddness is because we want to re-propogate any new states
    // coming from the evaluator.
    const [ viewingStateIndices, setViewingStateIndices ] = React.useState<Set<number>>(Set());

    React.useEffect(() => {
        trace && console.log('trig', viewingStateIndices.toArray(), states);

        if(alwaysEnd && !enabled.isEmpty()) {
            const max = enabled.max();
            if(max) {
                setViewingStateIndices(s => {
                    // Hacks. I can't figure out how to do this correctly
                    // without retriggering react in an infinite loop.
                    if(!s.equals(Set([ max ]))) {
                        return Set([ max ])
                    }
                    return s;
                });
                Root.setViewingMachineStates([ states[max] ]);
            }
        } else if(viewingStateIndices.isEmpty() && states.length > 0) {
            // const max = states.filter((s, idx) => enabled.contains(idx)).map(s => s.stack.length).reduce((a, b) =>
            // Math.max(a, b)); console.log("MAYBE?!", max) setViewingStateIndices(v => Set(states.filter(s =>
            // s.stack.length == max)));
            setViewingStateIndices(s => s.add(0));
            Root.setViewingMachineStates([ states[0] ]);
        } else {
            const viewingStates = viewingStateIndices.map(idx => states[idx])
            Root.setViewingMachineStates(viewingStates.toArray());
        }
    }, [ states, viewingStateIndices, alwaysEnd, tracked ]);

    const maxDepth = Math.max(...states.map(s => s.stack.length));

    const machineEntries = states.map((state, idx) => {
        const disabled = !enabled.contains(idx);
        const active = viewingStateIndices.contains(idx);
        const hasHovered = top(state.stack).bindings.some(b => b[0] === hoveredItem?.tok?.value);

        return (
            <div
                className={ classnames('state-entry', { disabled, active: !disabled && active, 'has-hovered': hasHovered }) }
                style={ { height: Math.floor(100 * state.stack.length / maxDepth) + '%' } }
                onMouseOver={ () => !disabled && setViewingStateIndices(v => Set([ idx ])) }
                key={ idx }
            >
                <div className="inner-marker"/>
            </div>
        );
    });

    return (
        <div className="timeline">
            <div className={ "machine-states" }>
                { machineEntries }
            </div>
            <div className={ "controls" }>
                <div
                    className={ classnames('arrow', { enabled: alwaysEnd }) }
                    onClick={ () => setAlwaysEnd(e => !e) }
                >
                    <FontAwesomeIcon icon={ faFastForward }/>
                </div>
            </div>
        </div>
    );
}

function calculateEnabledIndices(states: MachineState[], tracked: TrackedObjects): Set<number> {
    const out: number[] = [];
    let i = -1, j = 0;

    const has = hasAny(tracked);

    // Hmm......
    while(i < states.length && j < states.length) {
        const jframe = top(states[j].stack);

        if(has(jframe) && i > -1) {
            const iframe = top(states[i].stack);

            if(tracked.valueSeq().some(t => getBinding(false, iframe.bindings, t.tok.value) !== getBinding(false, jframe.bindings, t.tok.value))) {
                out.push(j);
                i = j;
            }
        } else if(has(jframe)) {
            out.push(j);
            i = j;
        }

        j++;
    }

    return Set(out);
}
