import { Program } from 'esprima';
import { ProcessedToken, SupportedNode, hasLoc, Span, Loc } from '../application';
import {Pattern} from "estree";


const trace = false;


function showLoc(loc: Span<Loc>): string {
    return `[${loc.start.line}:${loc.start.column}, ${loc.end.line}:${loc.end.column}]`;
}

function locLeq(a: Loc, b: Loc): boolean {
    return a.line < b.line ||
        (a.line == b.line && a.column <= b.column);
}

function locGeq(a: Loc, b: Loc): boolean {
    return a.line > b.line ||
        (a.line == b.line && a.column >= b.column);
}

function spanWithin(inner: Span<Loc>, outer: Span<Loc>): boolean {
    return locGeq(inner.start, outer.start) && locLeq(inner.end, outer.end);
}

export function findPath(prog: Program, tok: ProcessedToken): SupportedNode[] {
    function pi(n: SupportedNode | Pattern): SupportedNode[] {
        try {
            trace && console.groupCollapsed(`findPath from ${n.type}, for: `, tok);

            if(!hasLoc(n)) {
                trace && console.error(`Error! Missing location information for`, n);
                return [];
            }

            trace && console.log(`${showLoc(tok.loc)} \in ${showLoc(n.loc)}`);
            if(!spanWithin(tok.loc, n.loc)) {
                trace && console.log('NO');
                return [];
            }

            trace && console.log('YES');

            switch(n.type) {
                case 'FunctionDeclaration': {
                    const params = n.params.flatMap(pat => pi(pat));
                    return [n as SupportedNode].concat(pi(n.body)).concat(params);
                }

                case 'VariableDeclaration': {
                    return n.declarations.flatMap(dec => pi(dec.id));
                }

                case 'BlockStatement': {
                    const xs: SupportedNode[] = n.body.flatMap(s => pi(s));
                    return [n as SupportedNode].concat(xs);
                }

                case 'ForStatement': {
                    const init = n.init && pi(n.init) || [];
                    const test = n.test && pi(n.test) || [];
                    const update = n.update && pi(n.update) || [];

                    trace && console.log("FS", init, test, update);

                    const body: SupportedNode[] = Array.isArray(n.body) ? n.body.flatMap(pi) : pi(n.body);

                    return [n as SupportedNode].concat(init).concat(test).concat(update).concat(body);
                }

                case 'IfStatement': {
                    const test = pi(n.test);
                    const cons = pi(n.consequent);
                    const els  = n.alternate && pi(n.alternate);

                    const out = [n as SupportedNode].concat(test).concat(cons);

                    if(els) {
                        return out.concat(els);
                    }

                    return out;
                }

                case 'ReturnStatement':
                    return n.argument && pi(n.argument) || [];

                case 'Identifier':
                    return [n];

                default:
                    trace && console.warn('Won\'t process identifiers from', n.type);
                    return [];
            }
        } finally {
            trace && console.groupEnd();
        }
    }

    return prog.body.flatMap(st => pi(st));
}
