import { Program } from 'esprima';
import * as I from 'immutable';

import { BlockStatement } from 'estree';

import {
    Binding,
    defaultMachineState,
    EvaluableExpr,
    isBlockStatement,
    MachineState,
    MachineValue
} from '/src/application';

import { trace } from './logging';
import {
    addBindings,
    emptyGamma,
    getBinding,
    hgFromMachineState,
    hgToMachineStates,
    HistoricGamma,
    pushFrame
} from "./context";


import { logReturn } from "./utils";
import { evaluate } from './core';

import * as Ctx from './context';
(window as any).Ctx = Ctx;

////////////////////////////////////////////////////////////////////////////////


function renderEvalError(e: any, s?: Readonly<MachineState>[]) {
    trace && console.group('%cEvaluation Error', 'color: red');
    trace && console.error(e);
    trace && console.error('States were:', s);
    trace && console.groupEnd();
}

export function getGlobals(prog: Program, globals: Binding[] = []): MachineState {
    const initHg = addBindings(emptyGamma, I.List(globals))

    const ev = evaluate(initHg)

    try {
        const [ , hg ] = ev(prog);
        const allStates = hgToMachineStates(hg);
        return allStates[allStates.length - 1];
    } catch(e) {
        renderEvalError(e);
        return defaultMachineState;
    }
}

export function evaluateBoundFuncall(s: MachineState, fname: string, bs: Binding[]): [ MachineValue, MachineState[] ] {
    try {
        const hg = addBindings(emptyGamma, I.List(s.globals));
        const fn = getBinding(hg, fname);
        const body = fn.body;

        if(typeof body === 'undefined') {
            trace && console.error(hgToMachineStates(hg), fname);
            renderEvalError(`Tried to evaluate undefined for ${ fname }`);
            return [ undefined, [] ];
        }

        const val = evalFunctionBody(hg, bs, body);
        return [ val, hgToMachineStates(hg) ];

    } catch(e: any) {
        renderEvalError(e);
        return [ undefined, [] ];
    }
}

////////////////////////////////////////////////////////////////////////////////

function evalFunctionBody(hg: HistoricGamma, bs: Binding[], body: BlockStatement): [ MachineValue, MachineState[] ] {
    try {
        if(!isBlockStatement(body)) {
            renderEvalError(`Body not block statement!`);
            return [ undefined, [] ];
        }

        const h2 = addBindings(pushFrame(hg), I.List(bs));
        const [ val, outHg ] = evaluate(h2)(body);

        return logReturn([ val, hgToMachineStates(outHg) ], 'evalFunctionBody');
    } catch(e: any) {
        renderEvalError(e);
        return [ undefined, [] ];
    }
}


export function evaluateFromMachineState(ms: MachineState[]): (expr: Program | EvaluableExpr) => [ MachineValue, MachineState[] ] {
    return (expr: Program | EvaluableExpr): [ MachineValue, MachineState[] ] => {
        const [ val, gamma ] = evaluate(hgFromMachineState(ms))(expr);
        return [ val, hgToMachineStates(gamma) ];
    };
}
