import { Renderer } from '/src/application';
import { renderers } from '../machine/ui/renderers';


function addRendererForType(type: string) {
    return (name: string, render: Renderer<CanvasRenderingContext2D, any>['render']) => renderers.putRenderer(type, name, { render });
}

type WithTypeAndRenderer<T> = {
    type: string
    addRenderer: (name: string, r: Renderer<CanvasRenderingContext2D, T>['render']) => void
}

type Class<T> = new(...args: any[]) => T

export function serialize(arg: any) {
    console.log(JSON.stringify(arg));
}

export function typeFrom<T>(t: Class<T>): Class<T> & WithTypeAndRenderer<T> {
    console.log('typeFrom');

    const type = t.prototype.constructor.name;

    // Want: if  class X { x: number; constructor(x) { this.x = x; } }
    //       and const Y = typeFrom(X);
    //
    //       then: const z = new Y(3) => { x: 3, type: 'Y' }

    const nxt = function(this: WithTypeAndRenderer<T>) {
        this.type = type;
        t.prototype.constructor.apply(this, arguments);
    };


    nxt.prototype = t.prototype;
    nxt.addRenderer = addRendererForType(type);

    console.log(`Generated for ${type}`, nxt);

    return ((nxt as unknown) as Class<T> & WithTypeAndRenderer<T>);
}
