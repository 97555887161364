export const startProg1 = `const pts = Points.mkPointsInRect(
      10
    , new Point(0, 0)
    , new Point(10, 10));`;

export const startProg2 = `function what(x) {
  if(x == 4) return x + 1;
  return x;
}
 
console.log('eval test', what(4));`

export const startProg3 = `function countDown(x) {
  if(x > 0) {
    console.log(x);
    return countDown(x-1);
  }
}

countDown(10);
`

export const startProg4 = `function test() {
    for(let i = 0; i < 10; i++) {
        console.log(i);
    }
}`

export const startProg = `
const fpts = [
      new Point(-17.85116841668533, -3.6328086829052246)
    , new Point(-14.166350377655576, -5.030432355361167)
    , new Point(-10.742674488321803, 16.326858483978974)
    , new Point(-10.546358617102612, 7.338471056873214)
    , new Point(-5.635152072062436, 17.287514880575447)
    , new Point(4.334038905521876, 15.53485968863168)
    , new Point(8.48625607173506, 5.336343785861924)
    , new Point(13.037756272916518, -2.025337371417198)
    , new Point(13.561761127008296, 8.496080524651916)
];


const pts = Points.mkPointsInRect(
    10
    , new Point(-20, -10)
    , new Point(22, 22));
 
pts.sort((a, b) => a.x > b.x);
 
const lineTest = new Line([
    new Point(10, 10)
    , new Point(30, 30)
    //, new Point(40, 20)
]);
 
function divAndConquer(points) {
    if(points.length > 2) {
        const half     = Math.floor(points.length / 2);
        const leftPts  = points.slice(0, half);
        const rightPts = points.slice(half, points.length);
        const l        = divAndConquer(leftPts);
        const r        = divAndConquer(rightPts);
        const divOut   = merge(l, r);
        return divOut;
    } else {
        const base = new Line(points);
        return base;
    }
}
 
 
 
const l1 = new Line([
    new Point(15, 10)
    , new Point(20, 5)
]);
 
const l2 = new Line([
    new Point(1, 5)
    , new Point(5, 3)
    , new Point(10, 8)
]);
 
const l3 = new Line([
    new Point(3, 13)
    , new Point(8, 14)
]);
 
const l4 = new Line([
    new Point(13, 11)
]);
 
function slope(p1, p2) {
    return (p2.y-p1.y)/(p2.x-p1.x);
}
 
function keepSlopeBy(slopes, f) {
    let best = [0, slopes[0]];
    for(let i = 1; i < slopes.length; i++) {
        if(f(slopes[i], best[1])) {
            best = [i, slopes[i]];
        }
    }
    return best;
}

function merge(l, r) {
    const ptsL = [...l.points];
    const ptsR = [...r.points];
 
    const topL = ptsL.reduce((a, b) => (a.y == b.y && a.x < b.x) || a.y > b.y ? a : b);
    const topR = ptsR.reduce((a, b) => (a.y == b.y && a.x < b.x) || a.y > b.y ? a : b);
 
    const slopesL = ptsL.map(pt => new Line([pt, topR]));
    const slopeL = slopesL.reduce(
       (acc, x) => acc.slope() < x.slope() ? acc : x);
    
    const slopesR = ptsR.map(pt => new Line([topL, pt]));
    const slopeR = slopesR.reduce(
       (acc, x) => acc.slope() > x.slope() ? acc : x);
 
    const bridge = slopeL.absSlope() > slopeR.absSlope()
     ? slopeL
     : slopeR;

    const keepLeft = l.points.filter(pt => pt.x < bridge.points[0].x);
    const keepRight = r.points.filter(pt => pt.x > bridge.points[1].x);
 
    const out = new Line(keepLeft.concat(bridge.points).concat(keepRight));
    return out;
}
`;


/*

YES:
function keepSlopeBy(slopes, f) {
  let best = [0, slopes[0]];
  for(let i = 1; i < slopes.length; i++) {
console.log('checking', best, 'v', slopes[i]);
    if(f(slopes[i], best[1])) {
      best = [i, slopes[i]];
    }
  }
  return best;
}

Intermediate merge tries:

// Missing recursive calls.
function merge(l, r) {
  const ptsL = l.points;
  const ptsR = r.points;

  ptsL.sort((a, b) => a.y < b.y);
  ptsR.sort((a, b) => a.y < b.y);

  const topL = ptsL[0];
  const topR = ptsR[0];

  const outSet = [topL, topR];

  const fromL = l.points.filter(pt => pt.x < topL.x);
  const fromR = r.points.filter(pt => pt.x > topR.x);

  const out = new Line(fromL.concat(outSet).concat(fromR));

  return l;
}


// Not quite getting the right side.
function merge(l, r) {
if(l.points.length == 0) { return r; }
if(r.points.length == 0) { return l; }

  const ptsL = l.points;
  const ptsR = r.points;



  ptsL.sort((a, b) => a.y < b.y);
  ptsR.sort((a, b) => a.y < b.y);



  const topL = ptsL[0];
  const topR = ptsR[0];

  const outSet = [topL, topR];

  const fromL = l.points.filter(pt => pt.x < topL.x);
  const fromR = r.points.filter(pt => pt.x > topR.x);

  const ml = merge(new Line(fromL), new Line([topL]));
  const mr = merge(new Line([topR]), new Line(fromR));

  const out = new Line(ml.points.concat(outSet).concat(mr.points));

  return out;
}

// naive version
function merge(l, r) {
    // Setup
    const ptsL = l.points;
    const ptsR = r.points;

    ptsL.sort((a, b) => a.y < b.y);
    ptsR.sort((a, b) => a.y < b.y);

    const topL = ptsL[0];
    const topR = ptsR[0];

    // Base Cases
    if(l.points.length == 0) {
        return r;
    }
    if(r.points.length == 0) {
        return l;
    }


    const outSet = [ topL, topR ];

    const fromL = l.points.filter(pt => pt.x < topL.x);
    const fromR = r.points.filter(pt => pt.x > topR.x);

    // const ml = merge(new Line(fromL), new Line([ topL ]));
    // const mr = merge(new Line([ topR ]), new Line(fromR));

    // const out = new Line(ml.points.concat(outSet).concat(mr.points));

    const out = new Line(fromL.concat([topR]));

    return out;
}

------------------

function slope(p1, p2) {
  return (p2.y-p1.y)/(p2.x-p1.x);
}

function keepContiguousSlopes(slopes) {
  let out = [];
  for(let i = 0; i < slopes.length-1; i++) {
    if(slopes[i] < slopes[i+1]) {
       out.push(i);
    }
  }
  return out;
}

 */

// pts.sort((a, b) => a.x > b.x);

/*
Seg.addRenderer('default', ({ p1, p2 }) =>
  <line x1={ p1.x } y1={ p1.y }
        x2={ p2.x } y2={ p2.y }/>);

Seg.addRenderer('default', (seg) =>
  <line x1={ seg.p1.x } y1={ seg.p1.y }
        x2={ seg.p2.x } y2={ seg.p2.y } />);
 */

/******* OK RELIABLE LEFT MERGE ************
 *
 *
 function merge(l, r) {
    // Setup
    const ptsL = l.points;
    const ptsR = r.points;

    // Base Cases
    if (ptsL.length == 0) { return r; }
    if (ptsR.length == 0) { return l; }

    ptsL.sort((a, b) => a.y < b.y);
    ptsR.sort((a, b) => a.y < b.y);

    const topL = ptsL[0];
    const topR = ptsR[0];

    const outSet = [topL, topR];

    ptsL.sort((a, b) => a.x < b.x);
    ptsR.sort((a, b) => a.x < b.x);

    const slopesL = ptsL.map(pt => slope(pt, topR));
    const slopesR = ptsL.map(pt => slope(pt, topR));
    console.log("SlopesL!", slopesL);
    console.log("SlopesR!", slopesR);

    const minSlopeL = keepSlopeBy(slopesL, (a, b) => a < b);
    const minSlopeR = keepSlopeBy(slopesR, (a, b) => a > b);
    console.log("Kept SlopeL:", minSlopeL);
    console.log("Kept SlopeR:", minSlopeR);

    const boundingL = ptsL[minSlopeL[0]];
    const boundingR = ptsR[minSlopeR[0]];

    const leftPart = new Line([boundingL, topR]);
    const nextLeft = ptsL.filter(pt => pt.x < boundingL.x);

    //const rightPart = new Line([topL, boundingR]);
    //const nextRight = ptsR.filter(pt => pt.x > boundingR.x);

    const restLeft = merge(new Line(nextLeft), new Line([boundingL]));
    //const restRight = merge(new Line([boundingR]), new Line(nextRight));

    //const out = new Line(fromL.concat(outSet).concat(fromR));
    const out = new Line(restLeft.points.concat(leftPart.points));
    console.log('out', out);

    return out;
}
 */

/*

// I think these might be mutually recursive?????
function merge(l, r) {
    // Setup
    const ptsL = l.points;
    const ptsR = r.points;

    // Base Cases
    if (ptsL.length == 0) { return r; }
    if (ptsR.length == 0) { return l; }

    ptsR.sort((a, b) => a.y < b.y);

    const topR = ptsR[0];
    console.log('TopR:', topR);

    const slopesL = ptsL.map(pt => slope(pt, topR));
    console.log("SlopesL!", slopesL);

    const minSlopeL = keepSlopeBy(slopesL, (a, b) => a < b);
    console.log("Kept SlopeL:", minSlopeL);

    const boundingL = ptsL[minSlopeL[0]];

    const bridge = new Line([boundingL, topR]);

    const nextLeft = ptsL.filter(pt => pt.x < boundingL.x);
    const nextRight = [topR].concat(ptsR.filter(pt => pt.x > topR.x));

    const restLeft = merge(new Line(nextLeft), new Line([boundingL]));
    const restRight = new Line([])// divAndConquer(nextRight);

    const out = new Line(restLeft.points.concat(bridge.points).concat(restRight.points));

    return out;
}


IS THIS IT??

function m2(l, r) {
  const ptsL = [...l.points];
  const ptsR = [...r.points];

  ptsR.sort((a, b) => (a.y == b.y && a.x < b.x) || a.y > b.y);
  ptsL.sort((a, b) => (a.y == b.y && a.x < b.x) || a.y > b.y);

  const topL = ptsL.pop();
  const topR = ptsR.pop();

  const bridge = [topL, topR];
  const keepLeft = l.points.filter(pt => pt.x < topL.x);
  const keepRight = r.points.filter(pt => pt.x > topR.x);

  const out = new Line(keepLeft.concat(bridge).concat(keepRight));
  return out;

}
 */
