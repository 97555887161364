import * as I from "immutable";

const trace = false;

export function MiniMap<K, V>(vals?: Iterable<[ K, V ]> | ArrayLike<[ K, V ]>): MiniMap<K, V> {
    return new ListMap<K, V>(vals);
}

export interface MiniMap<K, V> {

    // the entire point of this exercise...
    swapEntries(k1: K, k2: K): MiniMap<K, V>;
    moveUp(key: K): MiniMap<K, V>;
    moveDown(key: K): MiniMap<K, V>;

    // All of this is borrowed from https://github.com/immutable-js/immutable-js/
    update(key: K, notSetValue: V, updater: (value: V) => V): MiniMap<K, V>;

    update(key: K, updater: (value: V | undefined) => V): MiniMap<K, V>;

    update<R>(updater: (value: this) => R): R;

    get<NSV>(key: K, notSetValue: NSV): V | NSV;

    get(key: K): V | undefined;

    has(key: K): boolean;

    delete(key: K): MiniMap<K, V>;

    remove(key: K): MiniMap<K, V>;

    set(key: K, value: V): MiniMap<K, V>;

    entrySeq(): I.Seq.Indexed<[ K, V ]>;

    keySeq(): I.Seq.Indexed<K>;

    valueSeq(): I.Seq.Indexed<V>;
}

type IndexedResult<K, V> = { idx: number, key: K, val: V }

class ListMap<K, V> implements MiniMap<K, V> {

    private store: I.List<[ K, V ]>

    constructor(vals?: Iterable<[ K, V ]> | ArrayLike<[ K, V ]>) {
        this.store = I.List(vals);
        trace && console.log('>> WITH STORE', this.store.toArray());
    }

    private indexedResult(key: K): IndexedResult<K, V> | undefined {
        const idx = this.store.findIndex(kv => kv[0] === key);
        if(idx >= 0) {
            const v = this.store.get(idx) as [ K, V ]; // can't be undefined, we just found it.
            return { idx, key, val: v[1] };
        }
        return undefined;
    }

    private indexedResultAt(idx: number): IndexedResult<K, V> | undefined {
        const entry = this.store.get(idx);
        if(entry) {
            return { idx, key: entry[0], val: entry[1] };
        }
        return undefined;
    }

    private swap(ir1: IndexedResult<K, V>, ir2: IndexedResult<K, V>): MiniMap<K, V> {
        const nl = this.store.remove(ir1.idx).insert(ir1.idx, [ ir2.key, ir2.val ])
            .remove(ir2.idx).insert(ir2.idx, [ ir1.key, ir1.val ]);
        return new ListMap(nl);
    }

    swapEntries(k1: K, k2: K): MiniMap<K, V> {
        const ir1 = this.indexedResult(k1);
        const ir2 = this.indexedResult(k2);
        if(ir1 && ir2) {
            this.swap(ir1, ir2);
        }

        return this;
    }

    moveUp(key: K): MiniMap<K, V> {
        const ir1 = this.indexedResult(key);
        if(ir1) {
            if(ir1.idx > 0) {
                const ir2 = this.indexedResultAt(ir1.idx-1);
                if(ir2) {
                    return this.swap(ir1, ir2);
                }
            }
        }
        return this;
    }

    moveDown(key: K): MiniMap<K, V> {
        const ir1 = this.indexedResult(key);
        if(ir1) {
            if(ir1.idx < this.store.size-1) {
                const ir2 = this.indexedResultAt(ir1.idx+1);
                if(ir2) {
                    return this.swap(ir1, ir2);
                }
            }
        }
        return this;
    }

    delete(key: K): ListMap<K, V> {
        const ir = this.indexedResult(key);

        if(ir) {
            return new ListMap(this.store.remove(ir.idx));
        }

        return this;
    }

    entrySeq(): I.Seq.Indexed<[ K, V ]> {
        return this.store.toSeq();
    }

    keySeq(): I.Seq.Indexed<K> {
        return this.entrySeq().map(kv => kv[0]);
    }

    valueSeq(): I.Seq.Indexed<V> {
        return this.entrySeq().map(kv => kv[1]);
    }


    get<NSV>(key: K, notSetValue: NSV): V | NSV;
    get(key: K): V | undefined;
    get(key: any, notSetValue?: any): any {
        const ir = this.indexedResult(key);
        if(ir) {
            return ir.val;
        }

        return notSetValue;
    }

    has(key: K): boolean {
        return !!this.indexedResult(key);
    }

    remove(key: K): ListMap<K, V> {
        return this.delete(key);
    }

    set(key: K, value: V): ListMap<K, V> {
        return this.update(key, _ => value);
    }

    update(key: K, notSetValue: V, updater: (value: V) => V): ListMap<K, V>;
    update(key: K, updater: (value: ( V | undefined )) => V): ListMap<K, V>;
    update<R>(updater: (value: this) => R): R;
    update(key: any, notSetValue?: any, updater?: any): ListMap<K, V> {
        if(typeof notSetValue === 'function' && typeof updater === 'undefined') {
            updater = notSetValue;
            notSetValue = undefined;
        }

        const ir = this.indexedResult(key);
        if(ir) {
            trace && console.log(">>>", ir);
            const nl = this.store.remove(ir.idx).insert(ir.idx, [ key, updater(ir.val) ]);
            return new ListMap(nl);
        } else {
            const nl = this.store.push([ key, updater(notSetValue) ]);
            return new ListMap(nl);
        }
    }
}
