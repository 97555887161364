import * as React from 'react';
import { Subscribe } from '@react-rxjs/core';
import 'rc-slider/assets/index.css';

import * as Root from '/src/composition-root';
import * as TrackedObjects from "/src/application/trackedObjects";

import { getBinding, MachineState, ObjOverrides, pathUnwrap, SupportedNode } from '/src/application';

import { renderers } from './renderers';
import { CanvasSvgRenderingContext } from "/src/machine/ui/svg-context";
import { SystemLog } from './SystemLog'
import { inTopOfStack } from './utils';

const trace = false;

const clamp = (low: number, high: number): (n: number) => number => (n: number) => {
    if(n < low) return low;
    if(n > high) return high;
    return n;
}

function lerp(start: number, end: number, steps: number): (n: number) => number {
    const c = clamp(0, steps);
    return (n: number) => c(n) / steps * ( end - start ) + start;
}

/**
 * This component is responsible for generating and maintaining an evaluated
 * {@link MachineState} given some inputs.
 *
 * In order to do this, it needs:
 *   - A program's parse tree
 *   - A set of {@link TrackedObjects}.
 *
 * If the tracked objects show up inside of a function, even if that function
 * has been evaluated by something in the global state, it will present controls
 * to re-execute that function with user input.
 *
 * This component is ALSO responsible for maintaining user control over
 * evaluation states (e.g. which and how many states are currently visible,
 * etc.).
 */


/*
 * TODO:
 *   * Convert to canvas?
 *     * While this would be far more flexible (and probably faster),
 *       I'd be giving up all of the built in UI handling (e.g. mouse events).
 *     * But is that worse than trying to figure out how to essentially
 *       re-implement a JSX compiler?
 *   * Source renderer needs to be able to show arbitrary expressions (e.g. the
 *     `new Point(...)` in mkPointsInRect)
 *   * Things on stage need to be linked back to the expression that created
 *     them, so that e.g. you can drag a point generated by `new Point(...)`
 */


const crsvg = new CanvasSvgRenderingContext();
renderers.setCtx(crsvg);

export const ME2: React.FC<{}> = () => {
    const viewingStates = Root.useViewingMachineStates();
    const trackedObjects = Root.useTrackedObjects();
    // const canvasRef      = React.useRef<HTMLCanvasElement | null>(null);
    //
    // React.useEffect(() => {
    //     if(canvasRef.current) {
    //         const ctx = canvasRef.current.getContext('2d');
    //         if(ctx) {
    //             renderers.setCtx(ctx);
    //             ctx.fillStyle = "white";
    //         }
    //     }
    // }, [canvasRef.current]);

    trace && console.log('ME2', viewingStates);
    const getOpacity = lerp(0, 1, viewingStates.length);

    viewingStates.forEach((s, idx) => {
        const inTop = inTopOfStack([s]);
        const opacity = getOpacity(idx + 1);

        trace && console.log('ME2 STATE RENDER', s, `opacity(${ idx + 1 }`, opacity);

        trackedObjects
            .valueSeq().toArray()
            .forEach(o => {
                const toRender = o.parsePath[o.parsePath.length - 1];

                if(typeof toRender === 'undefined') {
                    trace && console.warn("toRender was undefined for o:", o);
                } else if(toRender.type !== 'Identifier') {
                    trace && console.warn(`Don't know how to render ${ o.tok.value } (${ toRender.type })`);
                } else {
                    trace && console.log('ME2 OBJECT RENDER', o.parsePath, o.displayState, o.userColor, toRender);
                    const overrides: ObjOverrides = {};

                    trace && console.log(`ME2 ${o.tok.value} in stack??`, inTop(o));
                    overrides.opacity = inTop(o) ? 1.0 : 0.25;

                    overrides.hackMouseActions = {
                        over: () => TrackedObjects.setTrackedObjectState(o.tok, 'hover')
                        , out: () => TrackedObjects.setTrackedObjectState(o.tok, 'normal')
                    };

                    if(o.displayState == 'hover') {
                        overrides.fill = "var(--identifier-highlight-color)"
                        overrides.stroke = overrides.fill;
                    } else if(o.displayState == 'selected') {
                        overrides.fill = "green";
                        overrides.stroke = overrides.fill;
                    } else if(o.userColor) {
                        overrides.fill = o.userColor;
                        overrides.stroke = overrides.fill;
                    }

                    // TODO: pay attention to o.userColor & o.displayState
                    renderObj(s, overrides, toRender);
                }
            });
        trace && console.log();
    });

    // {<canvas ref={ canvasRef } />}

    return (
        <div className="col" style={ { 'flex': 1 } }>
            <svg
                className="render"
                width="100%"
                height="100%"
                viewBox="-25 -25 50 50"
                preserveAspectRatio="xMinYMin"
            >
                { crsvg.render() }
            </svg>
            <SystemLog/>
        </div>
    );
};

function renderObj(state: MachineState, overrides: ObjOverrides, n: SupportedNode): void {
    // Wait for the layers to clean up.
    if(typeof state === 'undefined') {
        console.warn("undefined machine state in renderObj...");
        return;
    }

    try {
        trace && console.log("check", n.type, n)
        if(n.type === 'Identifier') {
            const val = pathUnwrap(getBinding(false, state, n.name));
            trace && console.log(`rendering ${ n.name }=`, val, state);
            renderers.render(overrides, val);
        } else {
            console.warn(`Don't know how to extract value from '${ n.type }' node:`, n);
        }
    } catch(e) {
        console.error('Error in stage:', e);
    }
}

export const Stage: React.FC<{}> = () => {
    return (
        <div className="stage">
            <Subscribe fallback={ <p>Renderer Loading&hellip;</p> }>
                <ME2/>
            </Subscribe>
        </div>
    );
}
