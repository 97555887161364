import { MachineObject } from '../application';
import { System } from '.';
import * as Points from '/src/stdlib/points';
import {Point} from "/src/stdlib/points";

type LN = { points: Point[] };

export interface Line extends MachineObject<'line', LN> {
    slope: () => number
}

// export const Line = System.typeFrom<LN>(function Line(this: LN, points: (Point | Line)[]) {
// if(!Array.isArray(points)) { throw new Error('Line constructor expects an array!') }
// this.points = points.flatMap(p => p.type === 'line' ? p.points : [p]);
// });
export const Line = System.typeFrom<LN>(function Line(this: LN, points: Point[]) {
    this.points = points;
    this.slope = function() {
        return (this.points[0].y - this.points[1].y) / (this.points[0].x - this.points[1].x);
    }
    this.absSlope = () => Math.abs(this.slope())
});

// function concat(...lines: Line[]) {
//     function cat2(a: Line, b: Line) {
//         const l
//     }
// }

function dropLastPt(l: Line): Line {
    const npts = [...l.points];
    npts.splice(npts.length-1, 1);
    return new Line(npts);
}

Line.addRenderer('default', (_, ctx, { points }) => {

    if(points.length === 1) {
        const pt = points[0];

        ctx.beginPath();
        ctx.moveTo(pt.x-1, pt.y-1);
        ctx.lineTo(pt.x+1, pt.y+1);

        ctx.moveTo(pt.x+1, pt.y-1);
        ctx.lineTo(pt.x-1, pt.y+1);
        ctx.stroke();
    } else if(points.length >= 2) {
        ctx.beginPath();
        const pts = [ ...points ];
        const prev = pts.splice(0, 1)[0];

        ctx.moveTo(prev.x, prev.y);

        for(var next of pts) {
            ctx.lineTo(next.x, next.y);
        }

        ctx.stroke();
    }
});
