import { Binding, EvaluableExpr, isMachineFunction, MachineValue } from "../../application";
import { Program } from "esprima";

import * as SystemLog from "/src/machine/ui/SystemLog";

import {
    Directive,
    Expression,
    ModuleDeclaration,
    PrivateIdentifier,
    SpreadElement,
    Statement,
    VariableDeclarator
} from "estree";

import { kfoldLeft, loopy, Loopy } from "./continuation-helpers";
import { ForwardedCallExpression } from "./data";
import { trace } from './logging';
import { extractIdentifiers, isIdentifier, logReturn } from "./utils";

import * as I from "immutable";

import { addBindings, g, Gamma, getBinding, hgToMachineStates, HistoricGamma, popFrame, pushFrame } from "./context";
import { intoJs } from "/src/machine/eval/test-utils";

let historyLength = 0;

export function setHL(n: number): void {
    historyLength = n
}


export function evaluate(gamma: HistoricGamma): (expr: Program | EvaluableExpr) => [ MachineValue, HistoricGamma ] {
    setHL(gamma.size);
    SystemLog.clearSystemErrors();

    function wrap(e: EvaluableExpr | Program): [ MachineValue, HistoricGamma ] {
        try {
            if(e.type === 'Program') {
                trace && console.log('HOIST!');
                e.body.sort((ex1, ex2) => {
                    if(ex1.type === 'FunctionDeclaration' && ex2.type === 'FunctionDeclaration') {
                        return 0;
                    }
                    if(ex1.type === 'FunctionDeclaration') {
                        return -1;
                    }
                    if(ex2.type === 'FunctionDeclaration') {
                        return 1;
                    }

                    return 0;
                });
            }


            let pinged = false;
            trace && console.log('%cEvaluation Start', 'color: #00ff9f; size: 15pt', 'start bindings are', gamma);
            // const out = e2(e, false);

            let out: MachineValue = undefined;
            let hg: HistoricGamma;
            let hadError = false;
            ev(gamma, undefined, e
                , (gamma, msg, params) => {
                    console.error('error handler called:', msg, ...params);
                    hg = gamma;
                    hadError = true;
                    // TODO: WHYYYYYYYYYY
                    SystemLog.addSysMessage({ type: 'error', message: '[CORE ERR] ' + msg, params });
                    out = undefined;
                }
                , (gamma, val) => {
                    trace && console.log('Final gamma:', hgToMachineStates(gamma), 'ret val', val);
                    out = val;
                    hg = gamma;
                }, (gamma, thisVal, v) => {
                    if(!pinged) {
                        pinged = true;
                    } else {
                        throw new Error('NOPE');
                    }

                    trace && console.log('Final gamma\':', intoJs(gamma), 'output', v);
                    trace && console.log('Final gamma:', hgToMachineStates(gamma), 'output', v);
                    hg = gamma;
                });

            // @ts-ignore
            return [ out, hg ];
        } catch(e: any) {
            SystemLog.addSysMessage({ type: 'error', message: '[CORE]', params: [e.toString()] });
            return [ undefined, undefined ];
        }
    }

    return wrap;
}

function ev(
    gamma: HistoricGamma
    , thisVal: MachineValue
    , expr: Program | EvaluableExpr | ForwardedCallExpression | PrivateIdentifier | null | undefined
    , err: (gamma: HistoricGamma, msg: string, ...params: any[]) => void
    , ret: (gamma: HistoricGamma, v: MachineValue) => void
    , k: (gamma: HistoricGamma, thisVal: MachineValue, v: MachineValue) => void): void { // Might have to be pair of value + state trace
    // const k = (gamma: HistoricGamma, thisVal: MachineValue, v: MachineValue) => {
    //   trace && console.log('Continuing with ', v, 'this', thisVal, 'at', expr);
    //   k_(gamma, thisVal, v);
    // };

    // if(gamma.size >= historyLength) {
    //     historyLength = gamma.size;
    // } else {
    //     throw new Error(`Lost History; expected at least ${historyLength}, but got ${gamma.size}.`);
    // }

    if(typeof gamma === 'undefined') throw new Error('ERROR! GAMMA WAS UNDEFINED');
    if(typeof expr === 'undefined') return k(gamma, thisVal, undefined);
    if(expr === null) return k(gamma, thisVal, null);

    const maybeName = expr.type === 'CallExpression' && expr.callee.type === 'Identifier'
                      ? ( " " + expr.callee.name )
                      : ( expr.type === 'Identifier' ? ( " " + expr.name )
                                                     : "" );

    trace && console.log('%c' + ( expr && expr.type || 'EVALUATING UNDEFINED' ) + maybeName
        , 'color: #00ff9f'
        // , expr && expr.type
        , `[${ gamma.size }]`
        , gamma.map(stack => stack.map(gamma => gamma.toArray()).toArray()).toArray()
    );

    switch(expr.type) {
        case 'BlockStatement':
        case 'Program':
            const isBlock = expr.type === 'BlockStatement';
            return kfoldLeft<Directive | Statement | ModuleDeclaration, [ MachineValue, HistoricGamma ]>(
                [ undefined, gamma ]
                , I.List(expr.body)
                , ([ val, gamma ], expr, k1) => {
                    const whatIs = isBlock ? 'block statement' : 'top-level program statement'
                    return ev(gamma, thisVal, expr, err, (gamma, v) => {
                        trace && console.log(`%c${ whatIs } expression returned [hl: ${ gamma.size }]`, 'color: white', v);
                        ret(gamma, v)
                    }, (gamma, thisVal, v) => {
                        // trace && console.log(`%ctop-level prog expression returned`, 'color: white', v, 'gamma', gamma.toArray(), 'expr', expr);
                        trace && console.log(`%c${ whatIs } expression ended with value: [hl: ${ gamma.size }]`, 'color: white', v);
                        k1([ v, gamma ])
                    });
                }
                , ([ lastExprVal, newGamma ]) => k(newGamma, thisVal, isBlock ? undefined : lastExprVal) // TODO very incorrect...
            );


        case 'ExpressionStatement':
            return ev(gamma, thisVal, expr.expression, err, ret, k);

        case 'Literal':
            return k(gamma, thisVal, logReturn(expr.value, 'lit'));

        case 'Identifier':
            return k(gamma, thisVal, getBinding(gamma, expr.name));

        case 'VariableDeclaration': {
            return kfoldLeft<VariableDeclarator, HistoricGamma>(
                gamma
                , I.List(expr.declarations)
                , (gamma, decl, k1) => {
                    const id = decl.id;
                    if(id.type === 'Identifier') {
                        trace && console.log('DECL Evaluating Init... for', id.name);
                        return ev(gamma, thisVal, decl.init, err, ret, (gamma, thisVal, v) => {
                            trace && console.log(`Doclaring ${ id.name } with initial value`, v);
                            return k1(addBindings(gamma, I.List([ [ id.name, v ] ])));
                        });
                    }

                    return err(gamma, `Error: VariableDeclaration Id type '${ id.type }' is unsupported!`);
                }
                // Once I've mapped over all the bindings, I need to carry on with whatever I was going to do.
                , (gamma) => {
                    return k(gamma, thisVal, undefined);
                }
            );
        }

        case 'ArrayExpression':
            return kfoldLeft<Expression | SpreadElement | null, [ HistoricGamma, I.List<MachineValue> ]>(
                [ gamma, I.List() ]
                , I.List(expr.elements)
                , ([ gamma, vals ], a, k1) => {
                    if(a?.type === 'SpreadElement') {
                        return ev(gamma, thisVal, a.argument, err, ret, (gamma, thisVal, val) => k1([ gamma, vals.concat(val) ]));
                    } else {
                        return ev(gamma, thisVal, a, err, ret, (gamma, thisVal, val) => k1([ gamma, vals.push(val) ]));
                    }
                }
                , ([ gamma, vals ]) => {
                    trace && console.log('gamma', gamma.toArray(), 'vals', vals.toArray());

                    return k(gamma, thisVal, vals.toArray())
                });

        case 'FunctionDeclaration': {
            const params = extractIdentifiers(expr.params);
            const fv: MachineValue = { type: 'function', body: expr.body, params }

            const id = expr.id;

            if(id) {
                return k(addBindings(gamma, I.List([ [ id.name, fv ] ])), thisVal, fv);
            }

            return k(gamma, thisVal, fv);
        }

        case 'FunctionExpression':
        case 'ArrowFunctionExpression': {
            const params = extractIdentifiers(expr.params);
            const inheritsAmbientThis = expr.type == 'ArrowFunctionExpression';
            const fv: MachineValue = { type: 'function', body: expr.body, params, inheritsAmbientThis }

            return k(gamma, thisVal, fv);
        }

        // Here we go...
        case 'CallExpression':
            return ev(gamma, thisVal, expr.callee, err, ret, (gamma, thisVal, callee) =>
                typeof callee === 'undefined'
                ? err(gamma, 'callee in CallExpression was undefined', expr.callee)
                : kfoldLeft<[ Expression | SpreadElement, number ], [ I.List<MachineValue>, HistoricGamma ]>(
                    [ I.List(), gamma ]
                    , I.List(expr.arguments).map((x, idx) => [ x, idx ])

                    // Fold reducer
                    , ([ vals, gamma ], [ e, idx ], k1) => {
                        return ev(gamma, thisVal, e, err, ret, (gamma, thisVal, v) => k1([ vals.push(v), gamma ]))
                    }

                    // Continuation
                    , ([ vals, postParamGamma ]) => {
                        // Native JS Functions
                        if(typeof callee === 'function') {
                            const wrappedFnVals = vals.map(val => {
                                if(isMachineFunction(val)) {
                                    return (...args: any[]) => {
                                        const wrappedGamma: Gamma = g(postParamGamma).concat(val.params.map<[ string, MachineValue ]>((p, idx) => [ p, args[idx] ]));
                                        const wrappedHistoric = addBindings(pushFrame(postParamGamma), wrappedGamma);

                                        trace && console.log('%cArgs to wrapped fn: ', 'color: green', wrappedGamma.toArray())

                                        let out = undefined;
                                        ev(wrappedHistoric
                                            , thisVal
                                            , val.body
                                            , err
                                            , (postGamma, retVal) => {
                                                out = retVal;
                                            }
                                            , (postGamma, thisVal, retVal) => {
                                                out = retVal;
                                            }
                                        );
                                        return out;
                                    };
                                } else {
                                    return val;
                                }
                            });

                            trace && console.log('%cArgs to native fn:', 'color: orange', wrappedFnVals.toArray());
                            trace && console.log('%cthis:', 'color: orange', thisVal, callee);
                            const nout = callee.apply(thisVal, wrappedFnVals.toArray())
                            trace && console.log('%creturned:', 'color: orange', nout);

                            return k(postParamGamma, thisVal, nout);
                        }

                        if(callee.type == 'Super') {
                            return err(gamma, 'super() unsupported')
                        }

                        if(!isMachineFunction(callee)) {
                            console.log('nah machine function');
                            trace && console.error('Error: tried to call nonfunction:', callee);
                            return err(gamma, 'CallExpression Expected to get a machine function!');
                        }

                        const paramBindings = vals.map<Binding>((v, idx) => [ callee.params[idx], v ]);
                        const fgamma = paramBindings.push([ 'this', thisVal ]);
                        const fHistoric = pushFrame(postParamGamma, fgamma);

                        return ev(fHistoric, thisVal, callee.body, err
                            , (postGamma, retVal) => {
                                const nextFrame = popFrame(postGamma);
                                if(nextFrame.size <= postGamma.size) {
                                    return err(postGamma, 'woah')
                                }
                                return k(nextFrame, thisVal, logReturn(retVal, 'fn ret'));
                            }
                            , (postGamma, thisVal, retVal) => k(popFrame(postGamma), thisVal, logReturn(callee.inheritsAmbientThis ? retVal : undefined, 'fn complete')));
                    }
                ));

        // case 'SpreadElement':
        //     return ev(gamma, thisVal, expr.)

        case 'ReturnStatement':
            return ev(gamma, thisVal, expr.argument, err, () => {
                return err(gamma, 'Return in a return??');
            }, (gamma, thisVal, v) => ret(gamma, v));

        case 'ConditionalExpression':
        case 'IfStatement':
            return ev(gamma, thisVal, expr.test, err, ret, (gamma, thisVal, test) => {
                const cont = (gamma: HistoricGamma, thisVal: MachineValue, res: MachineValue) => {
                    if(expr.type === "ConditionalExpression") {
                        k(gamma, thisVal, res);
                    } else {
                        k(gamma, thisVal, undefined);
                    }
                };

                if(test) {
                    return ev(gamma, thisVal, expr.consequent, err, ret, cont);
                } else if(expr.consequent) {
                    return ev(gamma, thisVal, expr.alternate, err, ret, cont);
                }

                return k(gamma, thisVal, undefined);
            });

        case 'NewExpression':
            return ev(gamma, thisVal, expr.callee, err, ret, (gamma, thisVal, callee) =>
                typeof callee === 'undefined' ? err(gamma, 'callee in NewExpression was undefined', expr.callee) :
                kfoldLeft<[ Expression | SpreadElement, number ], [ I.List<MachineValue>, HistoricGamma ]>(
                    [ I.List(), gamma ]
                    , I.List(expr.arguments).map((x, idx) => [ x, idx ])
                    , ([ vals, gamma ], [ e, idx ], k1) =>
                        ev(gamma, thisVal, e, err, ret, (gamma, thisVal, v) => k1([ vals.push(v), gamma ]))
                    , ([ args, gamma ]) => {
                        trace && console.log('attempting to construct', callee);
                        const instance = new callee(...args);
                        return k(gamma, thisVal, instance);
                    }
                ))

        case 'MemberExpression':
            return ev(gamma, thisVal, expr.object, err, ret, (gamma, thisVal, object) => {
                const withPropValue = (gamma: HistoricGamma, propVal: MachineValue) => k(gamma, object, propVal);


                if(expr.computed || expr.property.type !== 'Identifier') {
                    trace && console.log('attempting to get a computed property from ', object);
                    if(typeof object === 'undefined') return err(gamma, 'Trying to get a property from undefined...');
                    return ev(gamma, thisVal, expr.property, err, ret, (gamma, thisVal, property) => {
                        if(typeof object === 'undefined') return err(gamma, '...that evaluated to', property);
                        trace && console.log('...which evaluated to', property, 'giving final answer:', object[property]);
                        return withPropValue(gamma, object[property]);
                    });
                } else if(expr.property.type === 'Identifier') {
                    if(typeof object === 'undefined') return err(gamma, `Trying to get '${ expr.property.name }' from undefined...`);
                    const out = object[expr.property.name];
                    trace && console.log('attempting to get', object, '.', expr.property.name, '=', out);
                    return withPropValue(gamma, logReturn(out, 'MemberExpression'));
                } else {
                    if(typeof object === 'undefined') return err(gamma, 'Trying to get a property from undefined...');
                    return err(gamma, 'Don\'t know what to do with ' + expr.property);
                }

            });

        case 'BinaryExpression':
            return ev(gamma, thisVal, expr.left, err, ret, (gamma, thisVal, l) =>
                ev(gamma, thisVal, expr.right, err, ret, (gamma, thisVal, r) => {
                    switch(expr.operator) {
                        case '==':
                            return k(gamma, thisVal, l == r);
                        case '!=':
                            return k(gamma, thisVal, l != r);
                        case '===':
                            return k(gamma, thisVal, l === r);
                        case '!==':
                            return k(gamma, thisVal, l !== r);

                        case '<':
                            return k(gamma, thisVal, l < r);
                        case '<=':
                            return k(gamma, thisVal, l <= r);

                        case '>':
                            return k(gamma, thisVal, l > r);
                        case '>=':
                            return k(gamma, thisVal, l >= r);

                        case '<<':
                            return k(gamma, thisVal, l << r);
                        case '>>':
                            return k(gamma, thisVal, l >> r);

                        case '>>>':
                            return k(gamma, thisVal, l >>> r);

                        case '+':
                            return k(gamma, thisVal, l + r);
                        case '-':
                            return k(gamma, thisVal, l - r);
                        case '*':
                            return k(gamma, thisVal, l * r);
                        case '/':
                            return k(gamma, thisVal, l / r);
                        case '**':
                            return k(gamma, thisVal, l ** r);

                        case '|':
                            return k(gamma, thisVal, l | r);
                        case '^':
                            return k(gamma, thisVal, l ^ r);
                        case '&':
                            return k(gamma, thisVal, l & r);

                        case 'in':
                            return k(gamma, thisVal, l in r);
                        case 'instanceof':
                            return k(gamma, thisVal, l instanceof r);

                        default:
                            return err(gamma, `Unknown operator ${ expr.operator }`);
                    }
                }));

        case 'LogicalExpression':
            return ev(gamma, thisVal, expr.left, err, ret, (gamma, thisVal, l) =>
                ev(gamma, thisVal, expr.right, err, ret, (gamma, thisVal, r) => {
                    switch(expr.operator) {
                        case "&&":
                            return k(gamma, thisVal, l && r);
                        case "||":
                            return k(gamma, thisVal, l || r);
                        case "??":
                            return k(gamma, thisVal, l ?? r);
                    }
                }));

        case 'UnaryExpression':
            return ev(gamma, thisVal, expr.argument, err, ret, (gamma, thisVal, argument) => {
                switch(expr.operator) {
                    case "-":
                        return k(gamma, thisVal, -argument);
                    case "+":
                        return k(gamma, thisVal, +argument);
                    case "!":
                        return k(gamma, thisVal, !argument);
                    case "~":
                        return k(gamma, thisVal, ~argument);
                    case "typeof":
                        return k(gamma, thisVal, typeof argument);
                    case "void":
                        return k(gamma, thisVal, void argument);
                    case "delete":
                        return err(gamma, 'TODO: `delete` unary operator not yet implemented.')
                }
            });

        case 'ForStatement':
            return ev(gamma, thisVal, expr.init, err, ret, (gamma, thisVal, init) => {
                const escape = (gamma: HistoricGamma) => k(gamma, thisVal, undefined);

                const loopBit = (gamma: HistoricGamma, loopy: Loopy<HistoricGamma>) =>
                    ev(gamma, thisVal, expr.test, err, ret, (gamma, thisVal, test) => {
                        const shouldLoop = typeof test === 'boolean' && test;
                        ev(gamma, thisVal, expr.body, err, ret, (gamma, thisVal, body) => {
                            ev(gamma, thisVal, expr.update, err, ret, (gamma, thisVal, v) => loopy(gamma, shouldLoop, loopBit, escape)); // HMMMMMM....
                        });
                    });

                return loopy(gamma, true, loopBit, escape);
            })

        case 'AssignmentExpression': {
            if(expr.left.type === 'Identifier') {
                const name = expr.left.name;
                return ev(gamma, thisVal, expr.left, err, ret, (gamma, thisVal, l) =>
                    ev(gamma, thisVal, expr.right, err, ret, (gamma, thisVal, r) => {
                        // Should be a way to combine this with the code from BinaryOp...
                        switch(expr.operator) {
                            case "=": {
                                const nv = r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "+=": {
                                const nv = l + r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "-=": {
                                const nv = l - r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "*=": {
                                const nv = l * r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "/=": {
                                const nv = l / r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "%=": {
                                const nv = l % r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "**=": {
                                const nv = l ** r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "<<=": {
                                const nv = l << r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case ">>=": {
                                const nv = l >> r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case ">>>=": {
                                const nv = l >>> r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "|=": {
                                const nv = l | r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "^=": {
                                const nv = l ^ r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                            case "&=": {
                                const nv = l & r;
                                const nb: Binding = [ name, nv ];
                                return k(addBindings(gamma, I.List([ nb ])), thisVal, nv);
                            }

                        }
                    }));
            } else {
                return err(gamma, 'AssignmentExpressions with lhs of ' + expr.left.type + ' are not yet implemented.');
            }
        }


        case 'UpdateExpression':
            return ev(gamma, thisVal, expr.argument, err, ret, (gamma, thisVal, argument) => {
                const id = expr.argument;
                if(!isIdentifier(id)) return err(gamma, `Expected ${ expr.argument } to be an Identifier!`);

                const delta = expr.operator == '++' ? 1 : -1;
                const out = expr.prefix ? argument + delta : argument;

                // HACK: wow. very good, much engineer
                const nb: Binding = [ id.name, argument + delta ];
                const ng: HistoricGamma = addBindings(gamma, I.List([ nb ]));
                return k(ng, thisVal, out);
            });

        default:
            console.log(`Processing for ${ expr.type } not yet written;`, expr);
            return err(gamma, `Processing for ${ expr.type } not yet written;`, expr);
    }
}
