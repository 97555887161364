import * as I from "immutable";
import { Binding, MachineState, MachineValue } from "/src/application";

import { trace } from './logging';

export type Gamma = I.List<Binding>
export type HistoricGamma = I.List<I.Stack<Gamma>>

// HG is: History [ Stack [ Stack Frame ]  ]
// i.e. a history of stacks with stack frames.

// A machine state has:
// globals
// a current state (deprecated)
// And a stack , which is an array of (arrays of) bindings...

export function hgToMachineStates(hg: HistoricGamma): MachineState[] {
    return hg.map(historicStack => {
        const m: MachineState = {
            globals: historicStack.flatMap(g => g).toArray()
            , state: { type: 'ok' }
            , stack: historicStack.map(stackFrame => ( { bindings: stackFrame.toArray() } )).toArray()
        };
        return m;
    }).toArray();
}

export function hgFromMachineState(ms: MachineState[]): HistoricGamma {
    return I.List(ms.map(state =>
        I.Stack(state.stack.map(scope => I.List(scope.bindings)))));
}


export const emptyGamma: HistoricGamma = I.List([ I.Stack([ I.List() ]) ]);

export function g(hg: HistoricGamma): Gamma {
    const out = hg.last(I.Stack<Gamma>()).peek();
    if(!out) {
        throw new Error('could not get g, hg is completely empty.');
    }
    return out;
}

export function pushFrame(hg: HistoricGamma, withBindings: Gamma = I.List()): HistoricGamma {
    trace && console.log('%cpush me', 'color:orange;');
    const top = hg.last();
    if(!top) throw new Error(`pushFrame had empty top!`)
    return hg.push(top.push(withBindings));
}

export function popFrame(hg: HistoricGamma): HistoricGamma {
    trace && console.log('%cand then just pop me', 'color:orange');
    const popped = hg.last()?.pop();
    if(!popped) {
        throw new Error('Could not pop frame!');
    }
    return hg.push(popped);
}

export function getBinding(hg: HistoricGamma, name: string): MachineValue {
    // console.log('looking for', name, 'in', hg.toArray().map(s => s.toArray().map(g => g.toArray())));
    const found = hg.last()?.peek()?.find(b => b[0] === name)?.[1];
    if(typeof found === 'undefined') {
        const next = hg.last()?.pop();
        // console.log('Checking', next);
        if(typeof next === 'undefined' || next.size == 0) return undefined;
        return getBinding(I.List<I.Stack<Gamma>>([ next ]), name);
    }
    return found;
}

// export function setBinding(hg: HistoricGamma, oldName: string, newVal: MachineValue) {
//     const stack = hg.last();
//     if(typeof stack === 'undefined') {
//         throw new Error('undef stack');
//     }
//
//     const top = stack.peek();
//     if(typeof top === 'undefined') {
//         throw new Error('empty stack');
//         // return hg.push(I.Stack([bs]));
//     }
//
//     const oneLess = stack.pop();
//     const withoutOld =
//
//     return hg.push(oneLess.push(bs.concat(top)));
//
// }

export function addBindings(hg: HistoricGamma, bs: I.List<Binding>): HistoricGamma {
    const stack = hg.last();
    if(typeof stack === 'undefined') {
        throw new Error('undef stack');
    }

    const top = stack.peek();
    if(typeof top === 'undefined') {
        throw new Error('empty stack');
        // return hg.push(I.Stack([bs]));
    }

    const oneLess = stack.pop();

    // Modifying history BABY
    // This will cause ONLY stack changes to be captured, which means
    // that things like loops won't work.
    // return hg.remove(hg.size - 1).push(oneLess.push(bs.concat(top)));

    // It produces cleaner output, but this is much more thorough:
    return hg.push(oneLess.push(bs.concat(top)));
}
