import * as React from 'react';

import { Stage } from './stage';
import { Layers } from './layers';
import { ExecTimeline } from './exec-timeline';

export const MachineUi: React.FC<{}> = ({}) => {

    return (
        <div className="machine-ui">
            <div className="col">
                <ExecTimeline />
                <Stage />
            </div>
            <Layers />
        </div>
    )
};
