import * as I from "immutable";

// export function kmap<T, U, OUT>(ts: I.List<T>, f: (t: T, k1: (u: U) => U) => U, k: (us: I.List<U>) => OUT): OUT {
//     return k(kfoldLeft<T, I.List<OUT>(
//         I.List()
//         , ts
//         , (acc, x, k) =>
//         ));
// }

export function kfoldLeft<ELT, OUT>(
    zero: OUT // `zero` doubles-up as the accumulator for the continuations
    , ts: I.List<ELT>
    , f: (acc: OUT, x: ELT, k: (nxt: OUT) => void) => void
    , k: (o: OUT) => void
): void {
    const hd = ts.first();
    if(hd) {
        const tl = ts.remove(0);
        return f(zero, hd, nxt => kfoldLeft(nxt, tl, f, k));
    }

    return k(zero);
}

export type Loopy<T> = (passThrough: T, shouldRepeat: boolean , repeat: (passThrough: T, next: Loopy<T>) => void , escape: (passThrough: T) => void) => void;
export function loopy<T>(
    passThrough: T, shouldRepeat: boolean , repeat: (passThrough: T, next: Loopy<T>) => void , escape: (passThrough: T) => void
): void {
    if(shouldRepeat) {
        return repeat(passThrough, loopy);
    }
    return escape(passThrough);
}
