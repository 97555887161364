import { BlockStatement } from 'estree';
import { Program } from 'esprima';
import { ProcessedToken, SupportedNode } from '.';
import { Map } from 'immutable';

const trace = false;

export type MachineObject<t extends string, T> = { type: t } & T;

export interface MachineFunction extends MachineObject<'function', { params: string[], body: BlockStatement, inheritsAmbientThis: boolean }> {  };
export interface MachineValWithContext extends MachineObject<'context', { context: MachineValue, val: MachineValue }> {  };
export interface MachineValueWithPath extends MachineObject<'path', { path: string, val: MachineValue }>{  };

export type MachineValue =
    | string | number | boolean | bigint | RegExp | null | undefined | object
    | MachineFunction
    | MachineValWithContext
    | MachineValueWithPath
    | MachineObject<any, any>;

export function isMachineObject<t extends string>(o: any): o is MachineObject<t, any> {
    return typeof o !== 'undefined' && typeof(o) == 'object' && 'type' in o;
}

export function isMachineFunction(v: MachineValue): v is MachineFunction {
    return (isMachineObject(v) && v.type == 'function') || false;
}

export function isMachineValWithContext(v: MachineValue): v is MachineValWithContext {
    return (isMachineObject(v) && v.type == 'context') || false;
}

export function isMachineValWithPath(v: MachineValue): v is MachineValueWithPath {
    return (isMachineObject(v) && v.type == 'path') || false;
}

export function isProgram(o: any): o is Program {
    return typeof o === 'object' && 'type' in o && o.type === 'Program';
}

export function isBlockStatement(o: any): o is BlockStatement {
    return typeof o === 'object' && 'type' in o && o.type === 'BlockStatement';
}

export function pathUnwrap(o: any): Exclude<MachineValue, MachineValueWithPath> {
    if(isMachineValWithPath(o)) return pathUnwrap(o.val);
    return o;
}

export function contextUnwrap(o: any): any {
    if(isMachineValWithContext(o)) return o.val;
    return o;
}

export interface ScopeState {
    bindings: Binding[]
}

export const defaultMachineState: MachineState = {
    globals: [ ]
    , stack: [{ bindings: [ ] }]
    , state: { type: 'ok' }
};

export type Binding = [string, MachineValue]

export function isBinding(b: any): b is Binding {
    return Array.isArray(b) && b.length == 2 &&
        typeof b[0] === 'string';
}

export type MachineStateType = { type: 'ok' } | { type: 'stuck', reason: string };

export type MachineState = {
    globals: Binding[]
    /** @deprecated
     *
     */
    state: MachineStateType
    stack: ScopeState[]
}

export type FlatState = {
    globals: Binding[]
    state: MachineStateType
    stack: ScopeState
}

export function isMachineState(o: any): o is MachineState {
    return (typeof o !== 'undefined' &&
             'state' in o &&
             'type' in o.state &&
                (o.state.type == 'ok' || o.state.type == 'stuck'));
}

export function hasBinding(bs: Binding[], n: string): boolean {
    return !!bs.find(([k, _]) => k === n);
}

export function getBinding(needsPath: boolean, bs: Binding[], n: string): MachineValue;
export function getBinding(needsPath: boolean, bs: MachineState, n: string): MachineValue;
export function getBinding(needsPath: boolean, bs: MachineState | Binding[], n: string): MachineValue {
    if(isMachineState(bs)) {
        const top = bs.stack[bs.stack.length - 1];
        if(hasBinding(top.bindings, n)) {
            return getBinding(needsPath, top.bindings, n);
        }

        const out = getBinding(needsPath, bs.globals, n);

        if(needsPath) {
            const val = pathUnwrap(out);
            trace && console.log("%cOK", 'color: orange', val, out);
            if(typeof val === 'undefined') trace && console.warn(`Unwrapped binding for ${n} is undefined`, out);

            return { type: 'path', path: n, val };
        } else {
            return pathUnwrap(out);
        }
    }

    const out = bs.find(b => b[0] === n)
    if(needsPath) {
        return { type: 'path', path: n, val: out && out[1] };
    } else {
        return out && pathUnwrap(out[1]);
    }
}


export function addBindings(to: MachineState, ...bs: Binding[]): MachineState;
export function addBindings(to: Binding[], ...bs: Binding[]): Binding[];
export function addBindings<E extends (Binding[] | MachineState)>(to: E, ...bs: Binding[]): E {
    if(isMachineState(to)) {
        return { ...to, globals: addBindings(to.globals) }
    }

    return bs.concat(to) as E;
}
