import * as React from 'react';
import {ReactElement} from "react";
import { HackMouseAction } from "/src/application";

const trace = false;

type DrawOp =
    { type: 'stroke-style', stroke: string }
    | { type: 'fill-style', fill: string }
    | { type: 'global-alpha', alpha: number }
    | { type: 'ellipse', x: number, y: number, radiusX: number, radiusY: number, rotation: number, startAngle: number, endAngle: number, counterclockwise?: boolean }
    | { type: 'line-to', x: number, y: number }
    | { type: 'move-to', x: number, y: number }
    | { type: 'rect', x: number, y: number, w: number, h: number }
    | { type: 'fill-text', text: string, x: number, y: number, maxWidth?: number }
    | { type: 'fill' }
    | { type: 'stroke' }
    | { type: 'begin-path' }
    | { type: 'mouse-action', action: HackMouseAction } // TODO OMG HACKS

export class CanvasSvgRenderingContext implements CanvasRenderingContext2D {
    private instructions: DrawOp[];

    constructor() {
        this.instructions = [];
    }

    set fillStyle(fill: string) {
        this.instructions.push({ type: 'fill-style', fill })
    }

    set strokeStyle(stroke: string) {
        this.instructions.push({ type: 'stroke-style', stroke })
    }

    set globalAlpha(alpha: number) {
        this.instructions.push({ type: 'global-alpha', alpha })
    }

    set mouseAction(action: { over: () => void, out: () => void }) {
        this.instructions.push({ type: 'mouse-action', action });
    }

    ellipse(x: number, y: number, radiusX: number, radiusY: number, rotation: number, startAngle: number, endAngle: number, counterclockwise?: boolean): void {
        this.instructions.push({ type: 'ellipse', x, y, radiusX, radiusY, rotation, startAngle, endAngle, counterclockwise })
    }

    lineTo(x: number, y: number): void {
        trace && console.log('line to', x, y);
        this.instructions.push({ type: 'line-to', x, y })
    }

    moveTo(x: number, y: number): void {
        trace && console.log('move to', x, y);
        this.instructions.push({ type: 'move-to', x, y })
    }

    rect(x: number, y: number, w: number, h: number): void {
        this.instructions.push({ type: 'rect', x, y, w, h })
    }

    beginPath() {
        trace && console.log('begin path');
        this.instructions.push({ type: 'begin-path' })
    }

    fill(): void {
        trace && console.log('fill');
        this.instructions.push({ type: 'fill' })
    }

    stroke(): void {
        trace && console.log('stroke');
        this.instructions.push({ type: 'stroke' })
    }

    fillText(text: string, x: number, y: number, maxWidth?: number) {
        trace && console.log('fill-text');
        this.instructions.push({ type: 'fill-text', text, x, y, maxWidth });
    }

    /*
     *  ctx.beginPath();
     *  ctx.ellipse(x, y, 10, 10, 0, 0, 2 * Math.PI);
     *  ctx.fill();
     * ctx.moveTo(10, 10);
     * ctx.
     */
    render(): React.ReactElement {
        trace && console.log("SVGContext Rendering: ", this.instructions);

        let subpaths: ReactElement[] = [];
        let render: ReactElement[] = [];
        let i = 0;

        let   x: number = 0
            , y: number = 0
            , fill: string = ""
            , stroke: string = ""
            , globalAlpha: number = 1.0
            , mouseAction: HackMouseAction = { out: () => {}, over: () => {} };

        for(var inst of this.instructions) {
            trace && console.log('INST: ' + inst.type, inst);
            switch(inst.type) {
                case "begin-path":
                    subpaths = []; x = 0; y = 0;
                    break;

                case "move-to":
                    x = inst.x; y = inst.y;
                    break;

                case "fill-style":
                    fill = inst.fill;
                    break;

                case "stroke-style":
                    stroke = inst.stroke;
                    break;

                case "global-alpha":
                    globalAlpha = inst.alpha;
                    break;

                case "mouse-action":
                    mouseAction = inst.action;
                    break;

                case "rect":
                    subpaths.push(
                        <rect
                            key={i++} x={inst.x + x} y={inst.y + y} width={inst.w} height={inst.h}
                            onMouseOver={ () => mouseAction.over() } onMouseOut={ () => mouseAction.out() }
                        />);
                    break;

                case "ellipse":
                    subpaths.push(
                        <ellipse
                            key={i++} cx={inst.x + x} cy={inst.y + y} rx={inst.radiusX} ry={inst.radiusY}
                            onMouseOver={ () => mouseAction.over() } onMouseOut={ () => mouseAction.out() }
                        />);
                    break;

                case "line-to":
                    subpaths.push(
                        <line
                            key={i++} x1={x} y1={y} x2={inst.x} y2={inst.y}
                            onMouseOver={ () => mouseAction.over() } onMouseOut={ () => mouseAction.out() }
                        />);
                    x = inst.x;
                    y = inst.y;
                    break;

                case "fill":
                    render.push(...(subpaths.map(e => ({
                        ...e, props: { ...e.props, fill, opacity: globalAlpha }
                    }))));
                    break;

                case "stroke":
                    render.push(...(subpaths.map(e => ({
                        ...e, props: { ...e.props, stroke, opacity: globalAlpha }
                    }))));
                    break;

                case "fill-text":
                    const style = inst.maxWidth ? { maxWidth: inst.maxWidth } : { };
                    render.push(
                        <text
                            fill={fill}
                            stroke={stroke}
                            x={inst.x}
                            y={inst.y}
                            style={style}
                            onMouseOver={ () => mouseAction.over() } onMouseOut={ () => mouseAction.out() }
                            transform={"scale(.1 -.1)"}
                        >{inst.text}</text>
                    );
                    break;

            }
        }

        this.instructions = [];
        return <g transform="scale(1 -1)">{render}</g>;
    }
}
