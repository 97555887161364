import { MachineObject } from '../application';
import { System } from '.';

export interface Point extends MachineObject<'point', { x: number, y: number }> {  }

// class ProtoPoint {
//     readonly x: number
//     readonly y: number

//     constructor(x: number, y: number) {
//         this.x = x;
//         this.y = y;
//     }
// }

type PT = { x: number, y: number };

export const Point = System.typeFrom<PT>(function Point(this: PT, x: number, y: number) {
    this.x = x;
    this.y = y;
});

(window as any).PT = Point;

Point.addRenderer('default', (_, ctx, { x, y }) => {
    ctx.beginPath();
    ctx.ellipse(x, y, 1, 1, 0, 0, 2 * Math.PI);
    ctx.fill();
});


function rr(low: number, high: number): number {
    const d = high - low;
    return Math.random() * d + low;
}

// N.B. Can't initialize javascript's random number generator with a seed...
export function mkPointsInRect(n: number, start: Point, end: Point): Point[] {
    function randPt(): Point {
        return new Point(
              rr(start.x, end.x)
            , rr(start.y, end.y)
        );
    }

    const out = [];
    for(var i = 0; i < n; i++) {
        out.push(randPt());
    }
    return out;
}

function eq(a: Point, b: Point): boolean {
    return a.type === 'point'
           && b.type === 'point'
           && a.x === b.x
           && a.y === b.y;
}
