import * as React from 'react';
import classnames from 'classnames';
import { FunctionDeclaration, Pattern } from 'estree';
import Color from 'color';

import { Map } from 'immutable';

import { BlockPicker } from 'react-color';

import * as app from '/src/application';
import { defaultMachineState, MachineState, MachineValue, ProcessedToken, TrackedObject } from '/src/application';
import * as Root from '/src/composition-root';
import * as SystemLog from "/src/machine/ui/SystemLog";
import { parseIt } from '/src/parse';
import * as Eval from '../eval';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";

import { inTopOfStack } from "/src/machine/ui/utils";

const trace = false;

function hasName<T, U, V>(t: T): t is ( T & { name: string } ) {
    return t !== null && typeof t === 'object'
           && 'name' in t;
}

export const Layers: React.FC<{}> = () => {
    const trackedObjects = Root.useTrackedObjects();
    const startState = Root.useStartMachineState();

    // TODO infinite loop???
    const viewingStates = Root.useViewingMachineStates();
    const nameInTopOfStack = inTopOfStack(viewingStates);

    const [ boundFunctionParams, setBoundFunctionParams ] = React.useState<Map<string, { dec: FunctionDeclaration, vals: Map<string, string> }>>(Map());
    const [ showColorPickerFor, setShowColorPickerFor ] = React.useState<string | undefined>(undefined);

    trace && console.log('globals start state', startState);

    // N.B. these are reversed because I display the layers upside down,
    //      so that the one on the top covers things below it.
    function moveUp(k: ProcessedToken) {
        app.moveTrackedObjectDown(k);
    }

    function moveDown(k: ProcessedToken) {
        app.moveTrackedObjectUp(k);
    }

    function getParamValue(fname: string, pname: string): MachineValue {
        return boundFunctionParams.get(fname)?.vals.get(pname)?.toString() || '';
    }

    function setParamValue(dec: FunctionDeclaration, pname: string, expr: string): void {
        // const eprog = parseIt(expr);
        // const val = eprog && evaluateExpr(startState, eprog.body[0]);
        // trace && console.log('SET PARAM VALUE >>>', expr, eprog, val);
        setBoundFunctionParams(fp => fp.update(dec.id.name, { dec, vals: Map() }, params => ( {
            ...params,
            vals: params.vals.set(pname, expr)
        } )));
    }

    function toggleColorPicker(k: string): void {
        setShowColorPickerFor(p => p === k ? undefined : k);
    }

    function hideColorPicker(k?: string | undefined): void {
        setShowColorPickerFor(undefined);
    }

    function shouldShowColorPicker(k: string): boolean {
        return showColorPickerFor === k;
    }

    function removeTrackedObject(t: ProcessedToken, dec?: FunctionDeclaration | undefined) {
        app.toggleTrackedObject(t);
        if(dec) {
            setBoundFunctionParams(fp => fp.remove(dec.id.name));
        }
    }

    React.useEffect(() => {

        trace && console.log('layers effect trig')
        try {
            // Many functions...
            const src = boundFunctionParams.entrySeq().toArray()
                .map(([ functionName, { dec, vals } ]) => {
                    if(vals.size < dec.params.length) {
                        return "";
                    }

                    const args = dec.params.map(p => vals.get(p.name)).join(", ");
                    return `${ functionName }(${ args }); `;
                }).join("");

            trace && console.log('src is?', src)

            if(src == "") {
                trace && console.log('layers blanking machine states');
                Root.setEvaluatedMachineStates([startState]);
            } else {
                const expr = parseIt(src);

                if(typeof expr === 'undefined') throw new Error('could not generate expr');

                let ev = Eval.evaluateFromMachineState([ startState ]);
                const [ val, hg ] = ev(expr);
                trace && console.log('src was', src, 'ret val', val, 'hg', hg);

                trace && console.log('layers setting machine states');
                Root.setEvaluatedMachineStates(hg);
            }

            // const evaluations = boundFunctionParams.entrySeq().toArray().map(([fname, params], idx) => {
            //     const bs: Binding[] = params.entrySeq().toArray().map(([k, v]) => {
            //         const parsed = parseIt(v)?.body[0];
            //         trace && console.groupCollapsed('Evaluating Parameter', k, '=', v);
            //         const out = parsed && ev(parsed);
            //         trace && console.log('Setting', k, 'to', out);
            //         trace && console.groupEnd();
            //
            //         return [k, out];
            //     });
            //
            //     return Eval.evaluateBoundFuncall(machineState, fname, bs);
            // });
            //
            // Root.setEvaluatedMachineStates(machineState.states);

        } catch(e: any) {
            SystemLog.addSysMessage({ type: 'error', message: '[LAYERS] ' + e.toString() });
        }

    }, [ boundFunctionParams, startState ])

    // TODO: Allow freezing globals (so other parts won't affect it)
    // TODO: Refresh definition
    // TODO: update definitions from global, because:
    // TODO: add new globals that don't show up in code
    // TODO: change rendering mode

    function renderParams(f: FunctionDeclaration): React.ReactElement[] {
        const id = f.id;
        if(id && id.type == 'Identifier') {
            const fname = id.name;

            return f.params
                .filter<Pattern & { name: string }>(p => hasName(p, 'name'))
                .map(p => (
                    <span key={ `${ fname }-${ p.name }` }>
                    <span>{ p.name }=</span>
                    <input
                        type="text"
                        value={ getParamValue(fname, p.name) }
                        onChange={ e => setParamValue(f, p.name, e.target.value) }
                    />
                </span>
                ));
        }

        return [ <span>NO FN NAME</span> ];
    }

    const keys = trackedObjects
        .valueSeq().reverse().toArray()
        .map((v) => {
            const topExpr = v.parsePath[0];
            trace && console.log('globals rendering', topExpr);

            const dec = (typeof topExpr !== 'undefined' && topExpr.type === 'FunctionDeclaration' && (topExpr as FunctionDeclaration)) || undefined;
            const params = typeof dec !== 'undefined' ? renderParams(dec) : [];

            return (
                <div
                    className={ classnames("global", 'row', v.displayState, { 'in-top': nameInTopOfStack(v) }) }
                    key={ v.tok.value }
                    onMouseOver={ () => app.setTrackedObjectState(v.tok, 'hover') }
                    onMouseOut={ () => app.setTrackedObjectState(v.tok, 'normal') }
                    onMouseUp={ (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    } }
                >
                    <div className="col color">
                        <div className="layer-controls">
                            <div
                                onClick={ () => moveUp(v.tok) }
                                className={ "arrow" }
                            >
                                <FontAwesomeIcon icon={ faArrowUp }/>
                            </div>
                            <div
                                className="swatch"
                                style={ { 'background': v.userColor.toString() } }
                                onClick={ () => toggleColorPicker(v.tok.value) }
                            />
                            <div
                                onClick={ () => moveDown(v.tok) }
                                className={ "arrow" }
                            >
                                <FontAwesomeIcon icon={ faArrowDown }/>
                            </div>
                        </div>
                        { shouldShowColorPicker(v.tok.value)
                          ? <div className="picker"
                                 onClick={ (e) => {
                                     e.preventDefault();
                                     app.setTrackedObjectState(v.tok, 'normal');
                                 } }>
                              <BlockPicker
                                  color={ v.userColor.toString() }
                                  onChange={ (c) => {
                                      app.setTrackedObjectState(v.tok, Color(c.hex));
                                      toggleColorPicker(v.tok.value);
                                  } }
                              /></div>
                          : null }
                    </div>
                    <div className="col">
                        <div className="row">
                            <span className="id-name">{ v.tok.value }</span>
                            <div className="column toolbar">
                                            <span
                                                className="delete"
                                                onMouseUp={ () => removeTrackedObject(v.tok, dec) }
                                            >
                                                <FontAwesomeIcon icon={ faTimesCircle }/>
                                            </span>
                            </div>
                        </div>
                        <div className="row params">
                            { params }
                        </div>
                    </div>
                </div>
            );
        });

    return (
        <div className="globals" onMouseUp={ () => hideColorPicker() }>
            { keys }
        </div>
    );
};
