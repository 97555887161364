import * as Rx from 'rxjs';
import { bind } from '@react-rxjs/core';
import * as stdlib from './stdlib';
import { Binding, MachineState, ProcessedToken } from './application/data';
import { parseIt } from './parse';
import * as ev from './machine/eval';
import { trackedObjects$ } from './application/trackedObjects';
import * as Ed from './ed/ed';
import { defaultMachineState } from './application';
import * as SystemLog from "/src/machine/ui/SystemLog";
import { IntegratedConsole } from "/src/stdlib/integrated-console";

const trace = false;

const extraGlobals$ = new Rx.BehaviorSubject<Binding[]>([
    [ 'Points', stdlib.Points ]
    , [ 'Point', stdlib.Points.Point ]
    , [ 'Lines', stdlib.Lines ]
    , [ 'Line', stdlib.Lines.Line ]
    , [ 'Math', Math ]
    , [ 'System', stdlib.System ]
    , [ 'console', IntegratedConsole ]
]);

// trace && trackedObjects$.subscribe(o => trace && console.log('trackedObjects:', o.entrySeq().toArray()));

const edits$ = new Rx.BehaviorSubject<Ed.Edits>({ type: 'noop' });
const { sourceLines$, cursorPosition$ } = Ed.edit(edits$);

const src$ = sourceLines$.pipe(Rx.map(l => l.join('\n')));
src$.subscribe(src => localStorage.setItem('src-doc', src));
// src$.subscribe(o => trace && console.log('src$', o));

const prog$ = src$.pipe(
    Rx.map(parseIt), Rx.shareReplay(1)
);

const toks$ = prog$.pipe(Rx.map(p => ( p && p.tokens || [] ) as ProcessedToken[]));
trace && toks$.subscribe(toks => trace && console.log('%cTOKS', 'color: green', toks));

// const prog$ = new Rx.BehaviorSubject<esprima.Program | undefined>(undefined);

// prog$.subscribe(o => trace && console.log("PROG", o));
// extraGlobals$.subscribe(o => trace && console.log("EXTRA GLOBALS", o));

const startMachineState$ = new Rx.BehaviorSubject<MachineState>(defaultMachineState);

Rx.combineLatest([ extraGlobals$, prog$ ])
    .subscribe(([ globals, prog ]) => {
        trace && console.log('%cNO PROG', 'color: red');
        if(prog) {
            trace && console.log('%cPROG IS', 'color: red', prog);
            if('errors' in prog && prog.errors.length === 0) {
                SystemLog.clearSystemErrors();
                const out = ev.getGlobals(prog, globals);
                startMachineState$.next(out);
            }
        }
    });

trace && startMachineState$.subscribe(o => trace && console.log('START MACHINE STATE', o));

const evaluatedMachineStates$ = new Rx.BehaviorSubject<MachineState[]>([]);
const viewingMachineStates$ = new Rx.BehaviorSubject<MachineState[]>([]);
const userGlobals$ = new Rx.BehaviorSubject<Binding[]>([]);

////////////////////////////////////////////////////////////////////////////////

export const [ useSrc ] = bind(src$);
export const [ useToks ] = bind(toks$);
export const [ useEdCursorPosition ] = bind(cursorPosition$);
export const [ useExtraGlobals ] = bind(extraGlobals$);
export const [ useProg ] = bind(prog$);
export const [ useStartMachineState ] = bind(startMachineState$);
export const [ useTrackedObjects ] = bind(trackedObjects$);
export const [ useEvaluatedMachineStates ] = bind(evaluatedMachineStates$);
export const [ useViewingMachineStates ] = bind(viewingMachineStates$);
export const [ useUserGlobals ] = bind(userGlobals$);

////////////////////////////////////////////////////////////////////////////////

export const setExtraGlobals = (n: Binding[]) => extraGlobals$.next(n);
export const setViewingMachineStates = (n: MachineState[]) => viewingMachineStates$.next(n);
export const setEvaluatedMachineStates = (n: MachineState[]) => evaluatedMachineStates$.next(n);
export const setUserGlobals = (n: Binding[]) => userGlobals$.next(n);
export const sendEdit = (n: Ed.Edits) => edits$.next(n);

////////////////////////////////////////////////////////////////////////////////


///// Initialization ///////////////////////////////////////////////////////////

