import { TrackedObject, hasBinding, MachineState, ScopeState, TrackedObjects } from "/src/application";

export function top<T>(xs: ScopeState[]): ScopeState {
    return xs[0];
    // return { bindings: xs.flatMap(x => x.bindings) };
}

export function hasAll(tracked: TrackedObjects): (s: ScopeState) => boolean {
    return s => tracked.valueSeq().every(t => hasBinding(s.bindings, t.tok.value));
}

export function hasAny(tracked: TrackedObjects): (s: ScopeState) => boolean {
    return s => tracked.valueSeq().some(t => hasBinding(s.bindings, t.tok.value));
}

export const inTopOfStack = (viewingStates: MachineState[]) => (o: TrackedObject):boolean =>
    viewingStates.some(s => top(s.stack).bindings.some(b => b[0] === o.tok.value));

