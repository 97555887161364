import { AssignmentProperty, Expression, Identifier, ObjectPattern, Pattern, Statement } from "estree";

import { trace } from "./logging";

export function isIdentifier(p: Pattern | Identifier | Expression | Statement | AssignmentProperty): p is Identifier {
    return p.type === 'Identifier';
}

export function isObjectPattern(p: Pattern | Identifier | Expression | Statement | AssignmentProperty): p is ObjectPattern {
    return p.type === 'ObjectPattern';
}

export function isAssignmentProperty(p: Pattern | Identifier | Expression | Statement | AssignmentProperty): p is AssignmentProperty {
    return p.type === 'Property' && p.kind === 'init' && !p.method;
}


export function logReturn<T>(t: T, context?: string): T {
    if(context) {
        trace && console.log(`%c [${ context }] Returned:`, 'color: orange', t);
    } else {
        trace && console.log('%cReturned:', 'color: orange', t);
    }
    return t;
}

////////////////////////////////////////////////////////////////////////////////

export type ParameterBinding = string | { to: string, from?: string }

export function toBinding(p: Pattern | AssignmentProperty): ParameterBinding[] {
    if(isIdentifier(p)) {
        return [p.name];
    } else if(isObjectPattern(p)) {
        return p.properties.flatMap(toBinding).map<ParameterBinding>(to => {
            if(typeof to === 'string') {
                return { to };
            }

            throw new Error(`${ to } is not a valid parameter binding`);
        });
    } else if(isAssignmentProperty(p)) {
        return toBinding(p.value);
    } else {
        trace && console.warn('Cannot convert', p, 'to a binding.');
        throw new Error(`Cannot convert ${ p } to a binding.`);
    }
}

export function extractIdentifiers(ps: ( Pattern | AssignmentProperty )[]): ParameterBinding[] {
    return ps.flatMap(toBinding);
}


// function getBinding(m: MachineState, name: string): MachineValue {
//     const top = m.stack[m.stack.length - 1];
//     const b = getBinding(top.bindings, name);

//     if(name in top.bindings) {
//         return top.bindings[name];
//     }

//     throw `Could not find ${name} in scope!`;
// }
