import * as esprima from 'esprima';

const trace = false;

export function parseIt(text: string): esprima.Program | undefined {
    try {
        return esprima.parseScript(text, { range: true, tokens: true, loc: true, jsx: true, tolerant: true });
    } catch (e) {
        trace && console.warn(e.description);
        return;
    }
}
